/**
 * 模块名称: 厂商id转移审批
 * @author zhuyan@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import VendorIdAudit from './VendorIdAudit'
import VendorIdAuditDetail from './VendorIdAuditDetail'

const VendorIdAuditIndex = ({ match, history }) => {

  return (
    <div>
      <Switch>
        <Route path={match.path + '/detail'} component={VendorIdAuditDetail} />
        <Route component={VendorIdAudit} />
      </Switch>
    </div>
  )
}

export default VendorIdAuditIndex