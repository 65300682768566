/**
 * 模块名称: 客户管理-负利收款
 */

import React, { useEffect, useState } from 'react'
import api from '@/api'
import {
  Input,
  message,
  Button,
  Table,
  Alert
} from 'antd'

const Column = Table.Column
let pageSize = 10
let currentPage = 1
let parr = [];
let ids = [];
const PaybackList = (props) => {

  const { drawerName, history } = props
  const [vendor, setVendor] = useState(null)
  const [moduleLoading, setModuleLoading] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [selectedCount, setSelectedCount] = useState(0)
  const [firstId, setFirstId] = useState(0);
  const [selectMoney, setSelectMoney] = useState(0);
  const [available, setAvailable] = useState(0);
  const [preMoney, setPreMoney] = useState({});
  const [total, setTotal] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    parr = [];
    setSelectMoney(0);
    setPreMoney({});
    setSelectedIds([]);
  }, [])


  //根据厂商id获取未收款订单
  const getOrderList = (typeVal) => {
    if (vendor !== null) {
      if (typeVal === '1') {
        setSelectMoney(0);
        setPreMoney({});
        setAvailable(0)
        setSelectedIds([]);
      }
      setModuleLoading(true);
      setOrderList([]);
      api.getOrderDirectCreditOrder({ firmId: vendor, limit: pageSize, page: currentPage }).then(data => {
        setOrderList(data.list);
        data.available && setAvailable(data.available)
        data.list.map((item) => {
          parr[item['id']] = item['colpredictprice'];
          ids.push(item['id']);
        })
        setTotal(data.count);
        setPreMoney(parr);
        setModuleLoading(false)
        setFirstId(data.list[0].id);

      }).catch(() => {
        setModuleLoading(false)
      })
    }
  }

  const onSearchVenderInfo = (e) => {
    e.target.value !== null && setVendor(e.target.value);
  }

  const rowSelection = {
    // selectedIds,
    onChange: (selectedRowKeys, selectedRows) => {
      let tempsum = 0;
      setSelectedCount(selectedRows.length)
      // setSelectedIds(selectedRowKeys);
      setSelectedIds(selectedRows.reduce((prev, curr) => [...prev, curr.id], []))

      selectedRowKeys.map((key) => {
        tempsum += +preMoney[key];
      })
      setSelectMoney(tempsum.toFixed(2));
    },

    selectedRowKeys: selectedIds
  }


  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    //history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getOrderList('2')
  }

  return (
    <>
      <div className="invoice-add-search-area">
        <span style={{ marginRight: 10 }}>请选择厂商ID</span>
        <Input placeholder="请输入厂商id" style={{ width: 300, marginRight: 10 }} onChange={onSearchVenderInfo} />
        <Button onClick={() => getOrderList('1')} type="primary" disabled={vendor === null || vendor == ''}>查询</Button>
      </div>
      <Alert className="count-alert" message={`总共${total}条数据`} showIcon/>
      <Table
        dataSource={orderList}
        rowKey="id"
        className="paybackorder-table"
        loading={moduleLoading}
        pagination={{
          pageSize,
          total: total,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        onChange={onChangeTable}
        rowSelection={rowSelection}
      >
        <Column title="项目" dataIndex="projectName" />
        <Column title="产品" dataIndex="productName" />
        <Column title="业务类型" dataIndex="businessName" />
        <Column title="订单编号" dataIndex="number" />
        <Column title="订单状态" dataIndex="auditStatusName" />
        <Column title="提单人" dataIndex="userName" />
        <Column title="预计收款" dataIndex="colpredictprice" />

      </Table>
      <div style={{marginTop: 20}}>
        <span>已选还款金额: {selectMoney}</span>
        <span style={{marginLeft: 50}}>可用余额: {available}</span>
      </div>
      <div style={{ margin: '15px 0' }}>
        {drawerName === '还款申请' && <Button disabled={selectedIds.length == 0} onClick={() => {

          if (firstId !== selectedIds[0]) {
            message.error('抱歉，只能从通过时间最早的一笔开始选择！');
            return;
          }

          if (+available < +selectMoney) {
            return message.error('抱歉，还款的金额不能大于账户可用余额，请重新选择！');
          }

          api.checkPayBackApplie({ vendor: vendor, ids: selectedIds.join(), type: 0 }).then(() => {
            selectedIds.length > 1 ?
            history.push('/orderDirect/paybackApplys?ids=' + selectedIds.join() + '&vendor=' + vendor)
            :
            history.push('/orderDirect/paybackApply?ids=' + selectedIds.join() + '&vendor=' + vendor)
          }).catch(() => {
            setModuleLoading(false)
          })

        }} type="primary" style={{ float: "right" }}>下一步</Button>}
      </div>
    </>
  )
}

export default PaybackList