/**
 * 模块名称: 厂商id转移审批
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Button,
  Input,
  Select,
  message,
  Icon,
  Popconfirm,
  Tooltip,
  Row,
  Col,
  DatePicker,
  Alert,
  Switch
} from 'antd'
import { parseSearch } from '@/utils'
// import Auth from '@/components/AuthMiddleware'
import CorpFilter from '@/components/CorpFilter'
import { Link } from 'react-router-dom'
import CryptoJS from 'crypto-js'
import './style.scss'

const { Column } = Table
const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option
let pageSize = 10
let currentPage = 1
let shouldMe = null
let apiOperateTimer = true

let filter = {
  companyId: '',
  outClient: '',
  outClientId: '',
  vendor: '',
  intotype: '',
  lastTimeMin: '',
  lastTimeMax: '',
  userName: '',
}

let intotypeArr = [{
  id: 0,
  name: '负责人'
}, {
  id: 1,
  name: '直销'
}, {
  id: 2,
  name: '代理商'
}, {
  id: 3,
  name: '主体'
}
]

const VendorIdAudit = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [expand, setExpand] = useState(false)
  const [auditStatusAll, setAuditStatusAll] = useState([]) // 审批状态
  // const [authList, setAuthList] = useState([])
  const [shouldMeDis, setShouldMeDis] = useState(true)//风控，防止用户频繁点击switch
  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10

    filter = {
      companyId: '',
      outClient: '',
      outClientId: '',
      vendor: '',
      intotype: '',
      lastTimeMin: '',
      lastTimeMax: '',
      userName: '',
    }
    shouldMe = null
    apiOperateTimer = true
    getList()
    getFilterData()
    // api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const getList = () => {
    if(apiOperateTimer){
      setLoading(true)
      if(shouldMe){
        filter.shouldMe = shouldMe
      }else{
        for(let i in filter){
          if(i == 'shouldMe')delete filter[i]
        }
      }
      api.getTransferDirectVendorList({ ...filter, limit: pageSize, page: currentPage })
        .then(data => {
          setLoading(false)
          setList(data.list)
          setCount(data.count)
        })
        .catch(() => setLoading(false))
        apiOperateTimer = false
        setShouldMeDis(false)
        let timer = setTimeout(() => {
          apiOperateTimer = true
          setShouldMeDis(true)
          clearTimeout(timer)
        }, 1000)
    }else{
      return message.info('请不要频繁操作')
    }
  }

  const onSetShouldMe = e => {
    if(e == true){
      shouldMe = 1
    }else{
      shouldMe = null
    }
    getList()
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        currentPage = 1
        history.replace(match.path)
        filter = {
          ...filter,
          ...vals,
          lastTimeMin: vals.lastTime ? vals.lastTime[0].format('YYYY-MM-DD') : '',
          lastTimeMax: vals.lastTime ? vals.lastTime[1].format('YYYY-MM-DD') : ''
        }
        delete filter.lastTime
        getList()
      }
    })
  }
 
  // 重置
  const onReset = () => {
    resetFields()
    shouldMe = null
    filter = {
      companyId: filter.companyId,
      outClient: '',
      outClientId: '',
      vendor: '',
      intotype: '',
      lastTimeMin: '',
      lastTimeMax: '',
      userName: '',
    }
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  // 筛选条件
  const getFilterData = () => {
    api.getAuditStatusList({ type: '17'}).then(data => {
      setAuditStatusAll(data)
    })
  }

  // 删除
  const onDelete = (id) => {
    api.delTransferVendorAudit({ id }, true).then(() => {
      message.success('删除成功')
      getList()
    })
  }

  // 补充资料(编辑)
  const onEdit = (data) => {
    const { muilt, id, clientId, client } = data
    if (muilt === 0 || muilt === 2) {
      history.push(`/csr/customer/newVendor?editId=${id}`, { muilt })
    } else if (muilt === 1) {
      history.push(`/csr/customer/newVendor?editId=${id}`, { muilt, clientId, client })
    }
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getList()
  }

  const onChangeCorp = (data) => {
    filter.companyId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  const onToggle = () => {
    setExpand(!expand)
  }

  
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 11},
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 13 },
    }
  }

  return (
    <div className="table-public">
      <CorpFilter onChange={onChangeCorp} />
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
        {/* <h4>其他选项: </h4> */}
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={8}>
                <FormItem label="转出客户名称" labelCol={{ span: 8}}>
                  {getFieldDecorator('outClient')(<Input placeholder="请输入转出客户名称" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="转出OAID" labelCol={{ span: 8 }}>
                  {getFieldDecorator('outClientId')(<Input placeholder="请输入转出OAID" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="厂商ID" labelCol={{ span: 8 }}>
                  {getFieldDecorator('vendor')(<Input placeholder="请输入厂商ID" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="转入类型" labelCol={{ span: 8 }}>
                  {getFieldDecorator('intotype')(
                    <Select placeholder="全部">
                      {intotypeArr.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="申请人" labelCol={{ span: 8 }}>
                  {getFieldDecorator('userName')(<Input placeholder="请输入申请人" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="最后处理时间" labelCol={{ span: 8 }}>
                  {getFieldDecorator('lastTime')(<RangePicker allowClear={false} span={12} />)}
                </FormItem>
              </Col>
              {/* <Col span={8}>
                <FormItem label="审批状态" labelCol={{ span: 8 }}>
                  {getFieldDecorator('auditStatus')(
                    <Select placeholder="全部" showSearch={true} optionFilterProp="children">
                      {auditStatusAll.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col> */}
            </Row>
            {/* <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="申请时间" labelCol={{ span: 8 }}>
                  {getFieldDecorator('applieTime')(<RangePicker allowClear={false} span={12} style={{ width: '140%' }} />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="转出时间" labelCol={{ span: 8 }}>
                  {getFieldDecorator('outTime')(<RangePicker allowClear={false} span={12} style={{ width: '140%' }} />)}
                </FormItem>
              </Col>
            </Row> */}
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{ cursor: 'pointer', fontSize: '12px', color: '#1890ff' }}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>

        <div className="recruit-search-btns">
          <Switch 
            checkedChildren="待审批" 
            unCheckedChildren="待审批" 
            disabled={!shouldMeDis} 
            onClick={onSetShouldMe} 
            className="shouldme-switch-btn" 
            checked={shouldMe ? true : false} 
          />
          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
        </div>
      </Form>
      <div className="line"></div>
      <div className="add-wrap"></div>
      <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 2000 }}
        onChange={onChangeTable}
      >
        <Column title="序号" dataIndex="id" fixed="left" width={100}/>
        <Column title="转出客户名称"
          dataIndex="client"
          fixed="left"
          width={200}
          // ellipsis={true}
          render={(name, record) => <div className='client'>
            <div className={record.clientNumber > 2 || record.clientNumber == 2 ? 'client1' : 'client2'} >
              <Link to={`${match.path}/detail?id=${record.id}&muilt=${record.muilt}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{name}</Link>
            </div>
            <a className="right">{record.clientNumber > 2 || record.clientNumber == 2 ? `等${record.clientNumber}个` : ''}</a>
          </div>} 
        />
        <Column width={180} title="转出OAID" dataIndex="clientId"  render={(name, record) => <div className='client'>
            <div className={record.clientIdNumber > 2 || record.clientIdNumber == 2 ? 'client3' : ''}>{name}</div>
            <div className="right">{record.clientIdNumber > 2 || record.clientIdNumber == 2 ? `等${record.clientIdNumber}个` : ''}</div>
          </div>
        }/>
        <Column width={188} title="厂商ID" dataIndex="vendor" render={(name, record) => <div className='client'>
            <div className={record.vendorNumber > 2 || record.vendorNumber == 2 ? 'client1' : ''}>{name}</div>
            <div className="right">{record.vendorNumber > 2 || record.vendorNumber == 2 ? `等${record.vendorNumber}个` : ''}</div>
          </div>
        }/>
        <Column title="转入类型" dataIndex="intoType" render={(name, record) => record.intoTypeLabel ? <u>{name}</u> : <div>{name}</div>}/>
        <Column title="转入客户/代理商" dataIndex="clientAgent" render={(name, record) => record.clientAgentLabel ? <u>{name}</u> : <div>{name}</div>}/>
        <Column title="转入OAID/QID" dataIndex="clientAgentId" render={(name, record) => record.clientAgentIdLabel ? <u>{name}</u> : <div>{name}</div>}/>
        <Column title="转入主体" dataIndex="branch" render={(name, record) => record.branchLabel ? <u>{name}</u> : <div>{name}</div>}/>
        <Column title="申请人" dataIndex="userName" />
        <Column title="所属销售" dataIndex="relSalerName" render={(name, record) => record.relSalerLabel ? <u>{name}</u> : <div>{name}</div>}/>
        <Column title="所属客服" dataIndex="relServicerName" render={(name, record) => record.relServicerLabel ? <u>{name}</u> : <div>{name}</div>}/>
        <Column title="所属代理商开发" dataIndex="relAgentStaffName" render={(name, record) => record.relAgentStaffLabel ? <u>{name}</u> : <div>{name}</div>}/>
        <Column title="审批状态" dataIndex="auditStatusName" />
        <Column title="最后处理时间" dataIndex="updateTime" />
        <Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
          <>
            {
              record.option.includes('edit') ?
                <Tooltip title="编辑" placement="bottom">
                  {/* <Link to={`/csr/customer/addCustomer?id=${record.id}`}> */}
                  <Icon className="operate-icon" style={{ color: '#1890FF' }} type="edit" onClick={() => onEdit(record)} />
                  {/* </Link>         */}
                </Tooltip>
                : null
            }
            {' '}
            {
              record.option.includes('delete') ?
                <Popconfirm title="确认删除吗？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                  <Tooltip title="删除" placement="bottom">
                    <Icon className="operate-icon" style={{ color: 'red' }} type="delete" />
                  </Tooltip>
                </Popconfirm> : null
            }
          </>
        )} />
      </Table>
    </div>
  )
}

export default Form.create()(VendorIdAudit)
