/**
 * 模块名称: 客户信息变更
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
  Tabs,
  Table,
  Form,
  Input,
  Select,
  Upload,
  message,
  Icon,
  Drawer,
  Button,
  Spin,
  Row,
  Col,
  Tooltip,
  Popconfirm,
  Modal
} from 'antd'
import api from '@/api'
import BtnGroup from '@/components/BtnGroup'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'
import ModifyInfo from './ModifyInfo'

const { TabPane } = Tabs
const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { Dragger } = Upload
let pageSize = 10
let currentPage = 1

let filter = {

}

const CustomerChange = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form
  const { location, history } = props
  const search = parseSearch(location.search)
  // const [detailList, setDetailList] = useState([])
  const [selectData, setSelectData] = useState({
    VerifyTypesArr: [], // 认证号码类型
    IndustryData: [], // 行业数据
    AreaDataArr: [], // 地区数据
    isRequest: false
  })
  const [formLoading, setformLoading] = useState(false)
  const draggerRef = useRef(null)
  const [fileList, setFileList] = useState([])
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [addType, setAddType] = useState(0)
  const [editVisible, seteditVisible] = useState(false)
  const [editLoading, setEditLoading] = useState(false)
  const [editId, setEditId] = useState(null)
  const [oaId, setEditoaId] = useState(null)
  const [nameDisabled, setNameDisabled] = useState(true) // 客户名称
  const [statusVal, setStatusVal] = useState('') // 1为已认证
  const [typeVal, setTypeVal] = useState('')
  const [toChange, setToChange] = useState(0)
  const [industryFlag, setIndustryFlag] = useState(true)
  const [twoIndustryArr, setTwoIndustryArr] = useState([]) //二级行业
  const [Modelvisible, setModelvisible] = useState(false)
  const [valModelvisible, setValModelvisible] = useState('')

  useEffect(() => {
    if (search.ID) {
      onClientIdEdit()
    } else {
      onEdit() // 客户管理点击编辑
    }
    getList()
  }, [])

  // 获取数据
  const getSelectData = () => {
    setformLoading(true)
    return Promise.all([
      api.getClientVerifyTypes({}),
      api.getSysIndustryList({ pid: 0 }),
      api.getClientGetAreaData({}),
    ]).then(list => {
      setformLoading(false)
      setSelectData({
        VerifyTypesArr: list[0],
        IndustryData: list[1],
        AreaDataArr: list[2],
        isRequest: true
      })
      return list
    }).catch(() => setformLoading(false))
  }

  // 客户名称变更审批 点击编辑
  const onClientIdEdit = () => {
    setNameDisabled(false)
    api.getClientChangeDetail({type: 1, id: search.ID }, true).then(async (res) => {
      await api.getSysIndustryList({ pid: res.newIndustry1 === 0 ? '' : res.newIndustry1 }).then(data => {
        setTwoIndustryArr(data)
      })
      await getSelectData().then(() => {
        setFieldsValue({
          newName: res.newName,
          // industry: res.industry,
          newIndustry1: res.newIndustry1 === 0 ? undefined : res.newIndustry1,
          newIndustry2: res.newIndustry2 === 0 ? undefined : res.newIndustry2,
          website: res.website,
          area: res.area,
          address: res.address,
          newVerifyType: res.newVerifyType,
          newVerifyNumber: res.newVerifyNumber,
          remark: res.remark
        })
        if (res.affix.length) {
          res.affix.forEach((item, index) => {
            fileList.push({
              status: 'done',
              uid: index,
              name: item.name,
              url: item.url
            })
          })
          setFileList(res.affix.map((item, index) => ({...item, uid: index})))
        }
        setTypeVal(res.newVerifyType)
      })
    })
  }


  // 客户管理点击编辑返显
  const onEdit = () => {
    api.getClientGetDetail({ oaId: search.oaId }, true).then(async (res) => {
      setStatusVal(res.status)
      if (res.status !== 1) {
        setNameDisabled(false)
      }
      await api.getSysIndustryList({ pid: res.industry1 === 0 ? '' : res.industry1 }).then(data => {
        setTwoIndustryArr(data)
      })
      await getSelectData().then(() => {
        setFieldsValue({
          newName: res.name,
          // industry: res.industry ? res.industry : undefined,
          newIndustry1: res.industry1 === 0 ? undefined : res.industry1,
          newIndustry2: res.industry2 === 0 ? undefined : res.industry2,
          website: res.website,
          area: res.area,
          address: res.address,
          newVerifyType: res.verifyType,
          newVerifyNumber: res.verifyNumber
          // affix
          // remark: res.remark
        })
        setTypeVal(res.verifyType)
      })
    })
  }

  // 获取二级行业
  const onSelectIndustry = (val) => {
    setFieldsValue({
      newIndustry2: undefined
    })
    api.getSysIndustryList({
      pid: val,
    }).then(data => {
      setTwoIndustryArr(data)
      setIndustryFlag(false)
    })
  }

  const onChangeTable = () => {
    // api.getStaffAlteration({ staffId: detailData.id }, true).then(data => {
    //   setDetailList(data.list)
    // })
  }

  const onNameDisabled = () => {
    setNameDisabled(false)
    setToChange(1)
  }

  // 文件上传 
  const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.gif, .png, .jpg, .jpeg, .PDF, .zip',
		allowSizeType: 2
	})

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 2 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 22 },
    }
  }

  //提交表单
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      let params = Object.assign({}, vals)
      let uploadList = []
      for (let i = 0, k = fileList.length; i < k; i++) {
        if (fileList[i].status !== 'removed') {
          uploadList.push({
            url: fileList[i].url,
            name: fileList[i].name
          })
        }
      }
      params.affix = uploadList
      if (search.oaId) params.clientId = search.oaId
      if (search.clientId) params.clientId = search.clientId
      if (search.ID) params.id = search.ID
      params.type = search.clientId ? 0 : toChange
      //console.log(match.path)
      if (!err) {
        api.getClientChangeHandle(params).then(res => {
          if (res.length == 0) {
            if (!search.oaId) {
              message.success('编辑成功')
              history.push('/csr/nameChangeAudit')
            } else {
              message.success('编辑成功')
              history.push('/csr/customer')
            }
          } else {
            // 确认弹窗展示
            if (res.isConfirm == true) {
              setModelvisible(true)
              setValModelvisible(res.msg)
            } else {
              setModelvisible(false)
            }
          }
        })
      }
    })
  }

  //取消发布
  const onCancel = () => {
    history.goBack()
  }

  // 联系人
  const getList = () => {
    setLoading(true)
    api.getClientContactList({ ...filter, clientId: search.oaId ? search.oaId: search.clientId, limit: pageSize, page: currentPage })
      .then(data => {
        setLoading(false)
        setList(data.list)
      })
      .catch(() => setLoading(false))
  }

  // 删除
  const onDelete = (id) => {
    api.getClientContactDel({ id }, true).then(() => {
      message.success('删除成功')
      getList()
    })
  }

  // 联系人(编辑)
  const onEditContact = (data) => {
    // console.log('data',data)
    seteditVisible(true)
    setAddType(1)
    setEditId(data.id)
    setEditoaId(search.oaId ? search.oaId: search.clientId)
  }

  // 新建联系人
  const onAddContact = () => {
    setAddType(0)
    setEditId('')
    seteditVisible(true)
    setEditoaId(search.oaId ? search.oaId: search.clientId)
  }

  // 类型
  const onSelectType = (typeval) => {
    setTypeVal(typeval)
    setFieldsValue({
      newVerifyNumber: ''
    })
  }

  // 温馨提示
  const hintTitle = () => {
    return <div className='customerAdd-hintTitle'>
      <Icon type="warning" theme="twoTone" twoToneColor="#faad14" style={{ fontSize: '26px' }} />
      <div className='hintText'>温馨提示</div>
    </div>
  }

  return (
    <div className="customerChangeSty customerSty">
      <Tabs defaultActiveKey="1" onChange={onChangeTable}>
        <TabPane tab="客户信息" key="1">
          <>
            <Form onSubmit={formSubmit} {...formItemLayout}>
              {/* 客户信息 */}
              <div className="CustomerLable">
                <div>
                  <Row gutter={24}>
                    <Col span={6}>
                      <FormItem label="客户名称" labelCol={{ span: 9 }}>
                        {getFieldDecorator('newName', {
                          rules: [{ required: true, message: '请输入客户名称' },
                          {
                            pattern: /^[\u4e00-\u9fa5|a-zA-Z|（）|《》｜、]{0,}$/,
                            message: '只支持中文、大小写英文字母、《》、中文括号及中文顿号',
                          }
                          ]
                        })(
                          <Input style={{ width: 200 }} placeholder="请输入客户名称" disabled={nameDisabled} />)}
                        {statusVal == 1 ? <a style={{ marginLeft: 5 }} onClick={onNameDisabled}>变更</a> : null}
                      </FormItem>
                    </Col>
                    {/* <Col span={6}>
                      <div className="csrManageNo">
                        <FormItem label="所属行业" labelCol={{ span: 9 }}>
                          {getFieldDecorator('industry', {
                            rules: [{ required: true, message: '请选择所在行业' }]
                          })(
                            <Select placeholder="请选择"
                              style={{ width: 200 }}
                              dropdownMatchSelectWidth={false}
                            >
                              {selectData.IndustryData.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                            </Select>
                          )}
                        </FormItem>
                      </div>
                    </Col> */}
                    <Col span={6}>
                      <FormItem label="一级行业" labelCol={{ span: 9 }}>
                        {getFieldDecorator('newIndustry1', {
                          rules: [{ required: true, message: '请选择一级行业' }]
                        })(
                          <Select placeholder="请选择"
                            style={{ width: 200 }}
                            dropdownMatchSelectWidth={false}
                            onSelect={onSelectIndustry}
                            disabled={nameDisabled}
                            showSearch={true}
                            optionFilterProp="children"
                          >
                            {selectData.IndustryData.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                    <Col span={6}>
                      <FormItem label="二级行业" labelCol={{ span: 9 }}>
                        {getFieldDecorator('newIndustry2', {
                          rules: [{ required: twoIndustryArr.length === 0 ? false : true, message: '请选择二级行业' }]
                        })(
                          <Select placeholder="请选择"
                            style={{ width: 200 }}
                            dropdownMatchSelectWidth={false}
                            showSearch={true}
                            optionFilterProp="children"
                            disabled={industryFlag}
                          >
                            {twoIndustryArr && twoIndustryArr.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                    <Col span={6}>
                      <FormItem label="网址" labelCol={{ span: 6 }}>
                        {getFieldDecorator('website', {
                          rules: [{ required: true, message: '请输入网址' }]
                        })(<Input style={{ width: 200 }} placeholder="请输入网址" />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={6}>
                      <div className="csrManageNo">
                        <FormItem label="地区" labelCol={{ span: 6 }}>
                          {getFieldDecorator('area', {
                            rules: [{ required: true, message: '请选择地区' }]
                          })(
                            <Select placeholder="请选择"
                              style={{ width: 200 }}
                              dropdownMatchSelectWidth={false}
                            >
                              {selectData.AreaDataArr.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                            </Select>
                          )}
                        </FormItem>
                      </div>
                    </Col>
                    <Col span={6}>
                      <FormItem label="地址" labelCol={{ span: 6 }}>
                        {getFieldDecorator('address', {
                          rules: [{ required: true, message: '请输入地址' }]
                        })(<Input style={{ width: 200 }} placeholder="请输入地址" />)}
                      </FormItem>
                    </Col>
                    <Col span={6}>
                      <FormItem label="认证号码类型" labelCol={{ span: 12 }}>
                        {getFieldDecorator('newVerifyType', {
                          rules: [{ required: true, message: '请选择认证号码类型' }]
                        })(
                          <Select placeholder="请选择"
                            style={{ width: 200 }}
                            dropdownMatchSelectWidth={false}
                            disabled={nameDisabled}
                            onSelect={onSelectType}
                          >
                            {selectData.VerifyTypesArr.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                    <Col span={6}>
                      <FormItem label="认证号码" labelCol={{ span: 9 }}>
                        {getFieldDecorator('newVerifyNumber', {
                          normalize: newVerifyNumber => {
                            if (typeVal == 0 && newVerifyNumber !== undefined) {
                              return newVerifyNumber.toUpperCase()
                            } else {
                              return newVerifyNumber
                            }
                          },
                          rules: [{ required: true, message: '请输入认证号码' }]
                        })(<Input style={{ width: 200 }} placeholder="请输入认证号码" disabled={nameDisabled} />)}
                      </FormItem>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="line"></div>

              {/* 其他信息 */}
              <div>
                <h4 style={{ marginTop: 12 }}>其他信息</h4>
                <div className="customerLine"></div>
                <div style={{ marginTop: 50 }}>
                  <FormItem label="附件">
                    <div className="dragger-box" ref={draggerRef}>
                      <Dragger
                        {...uploadFiles}
                      >
                        <p className="ant-upload-drag-icon">
                          <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                        <p className="ant-upload-hint">
                          附件支持格式：GIF、PNG、JPG、PDF、zip  支持添加多个附件，单个附件大小不超过5M，文件数量不超过5个。
						</p>
                      </Dragger>
                    </div>
                  </FormItem>
                  <FormItem label="备注">
                    {getFieldDecorator('remark')(
                      <Input.TextArea placeholder="请输入备注" maxLength={100} />
                    )}
                  </FormItem>
                </div>
              </div>
              <div style={{ paddingRight: 50 }}>
                <BtnGroup onCancel={onCancel} confirmName='提交' right />
              </div>
            </Form>
          </>
        </TabPane>
        <TabPane tab="联系人信息" key="2">
          <div className="add-customer">
            {/* <Auth auths={authList} code="staff-attendance-apply"> */}
            <Button type="primary" className="add-customerBtn" onClick={onAddContact}>新建联系人</Button>
            {/* </Auth> */}
          </div>
          <Table
            dataSource={list}
            rowKey="id"
            className="table-page"
            loading={loading}
            pagination={false}
          // pagination={{
          //   pageSize,
          //   total: count,
          //   current: currentPage,
          //   showQuickJumper: true,
          //   showSizeChanger: true
          // }}
          // scroll={{ x: 2200 }}
          // onChange={onChangeTable}
          >
            <Column title="联系人姓名" dataIndex="name" />
            <Column title="手机号码" dataIndex="mobile" />
            <Column title="邮箱" dataIndex="mail" />
            <Column title="创建时间" dataIndex="addTime" />
            <Column title="创建人" dataIndex="creatorName" />
            <Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
              <>
                {
                  <Tooltip title="编辑" placement="bottom">
                    <Icon className="operate-icon" style={{ color: '#1890ff' }} type="edit" onClick={() => onEditContact(record)} />
                  </Tooltip>
                }
                {' '}
                {
                  <Popconfirm title="确认删除吗？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                    <Tooltip title="删除" placement="bottom">
                      <Icon className="operate-icon" style={{ color: 'red' }} type="delete" />
                    </Tooltip>
                  </Popconfirm>
                }

              </>
            )} />
          </Table>
          <Drawer
            title={editId ? '编辑联系人' : '新建联系人'}
            width={600}
            onClose={() => { seteditVisible(false) }}
            visible={editVisible}
            destroyOnClose={true}
          >
            <Spin spinning={editLoading}>
              <ModifyInfo
                setEditLoading={setEditLoading}
                seteditVisible={seteditVisible}
                getList={getList}
                addType={addType}
                editId={editId}
                oaId={oaId}
              />
            </Spin>
          </Drawer>
        </TabPane>
      </Tabs>
      <Modal
        title={hintTitle()}
        visible={Modelvisible}
        onOk={() => setModelvisible(false)}
        maskClosable={false}
        closable={false}
        okText="确认"
        onCancel={() => setModelvisible(false)}
        width='50%'
      >
        <div style={{ whiteSpace: 'pre-wrap' }}>
          {valModelvisible}
        </div>
      </Modal>
    </div>
  )
}
export default Form.create()(CustomerChange)
