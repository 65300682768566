/**
 * 模块名称: 厂商id审批详情页
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect } from 'react'
import {
  Descriptions,
  Steps,
  Button,
  Modal,
  Input,
  Table,
  Icon,
  DatePicker,
  message,
  Radio
} from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import moment from 'moment'
import CryptoJS from 'crypto-js'

const dItem = Descriptions.item
const { Step } = Steps;
const { Column } = Table

const VendorIdAuditDetail = (props) => {
  const { match, location, history } = props
  const [detailData, setDetailData] = useState({})
  const [outInfoData, setOutInfoData] = useState({}) // 转出数据
  const [outInfoVendorsData, setOutInfoVendorData] = useState([]) // 转出数据数组

  const [detailDataId, setdetailDataId] = useState('')
  const [statusList, setStatusList] = useState([])
  const [logConList, setLogConList] = useState([])
  const [buttonAuthList, setButtonAuthList] = useState([])
  const [passModalVisible, setModalVisible] = useState(false) //确认弹框
  const [turnModalVisible, setTurnModalVisible] = useState(false) //驳回弹框
  const [textAreaVal, setTextAreaVal] = useState('') //判断框是否为空
  const [textDisplay, setTextDisplay] = useState(true) //是否提示必选
  const [pasDesc, setPasDesc] = useState('')  //通过意见
  const [showFile, setShowFile] = useState([]) //附件
  const [showProfermanceDateNode, setShowProfermanceDateNode] = useState(false)//是否显示：是否算新开业绩
  const [performanceTimeNode, setPerformanceTimeNode] = useState(undefined)//当是否算新开业绩选择 是 的时候的日期值
  const [nodeTimeDis, setNodeTimeDis] = useState(true)
  const [intoText, setIntoText] = useState({})

  const search = parseSearch(location.search)

  useEffect(() => {
    let plaintext = search.sign
    let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

    if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
      api.getTransferDirectVendorDetail2({ id: search.id }, true).then(data => {
        if (search.msgId) {
          api.setMessageReaded({ ids: [search.msgId] })
        }
        setShowProfermanceDateNode(data.showProfermanceDateNode)
        setDetailData(data)
        setOutInfoData(data.outText)
        setOutInfoVendorData(data.outText.vendors)
        setIntoText(data.intoText)
        setdetailDataId(data.id)
        setStatusList(data.auditProgress) // 审批流
        setLogConList(data.auditLog) // 审批记录表8
        setButtonAuthList(data.buttonAuthList) // 审批权限
        setShowFile(data.files) //附件
      })
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])

  // 通过
  const onBtnPassShow = () => {
    setModalVisible(true)
  }

  const onPassCancel = () => {
    setModalVisible(false)
  }

  const onPassDescChange = (e) => {
    setPasDesc(e.target.value)
  }

  const onApprove = () => {
    let params = {
      id: detailDataId,
      type: 0,
      remark: pasDesc
    }

    //是否算新开业绩
    if (showProfermanceDateNode) {
      if (!nodeTimeDis) {
        if (!performanceTimeNode) {
          return message.warning('请选择算新开业绩日期')
        }
        params.nodeTime = moment(performanceTimeNode).format('YYYY-MM-DD')
      }
    }
    api.transferVendorAudit(params).then(() => {
      api.getTransferDirectVendorDetail2({ id: search.id }, true).then(data => {
        setDetailData(data)
        setOutInfoData(data.outText)
        setOutInfoVendorData(data.outText.vendors)
        setdetailDataId(data.id)
        setStatusList(data.auditProgress) // 审批流
        setLogConList(data.auditLog) // 审批记录表
        setButtonAuthList(data.buttonAuthList) // 审批权限
        setShowFile(data.files) //附件
      }) //确认通过后
    })
    setModalVisible(false)
  }

  // 驳回
  const onBtnTurnShow = () => {
    setTurnModalVisible(true)
    setTextDisplay(true)
  }

  const onTurnCancel = () => {
    setTurnModalVisible(false)
  }

  const onTurnApprove = () => {
    if (buttonAuthList.reject_msg_require) {
      if (textAreaVal.trim() === '') {
        setTextDisplay(false)
        return
      }
    }
    api.transferVendorAudit({
      id: detailDataId,
      type: 1,
      remark: textAreaVal
    }).then(() => {
      api.getTransferDirectVendorDetail2({ id: search.id }, true).then(data => {
        setDetailData(data)
        setOutInfoData(data.outText)
        setOutInfoVendorData(data.outText.vendors)
        setdetailDataId(data.id)
        setStatusList(data.auditProgress) // 审批流
        setLogConList(data.auditLog) // 审批记录表
        setButtonAuthList(data.buttonAuthList) // 审批权限
        setShowFile(data.files) //附件
      }) //确认驳回后
    })
    setTurnModalVisible(false)
  }

  const onDescChange = (e) => {
    setTextAreaVal(e.target.value)
    setTextDisplay(true)
  }

  // 驳回样式
  const turnNode = () => {
    return <>
      确认驳回 <Icon type="close-circle" theme="twoTone" twoToneColor="#DC143C" />
    </>
  }

  // 同意样式
  const agreeNode = () => {
    return <>
      确认通过 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
    </>
  }

  // 转上级
  const onTurnUp = () => {
    api.transferVendorAudit({
      id: detailDataId,
      type: 2,
      remark: ''
    }).then(data => {
      history.push('/csr/vendorIdAudit')
    }).catch()
  }

  // 补充资料
  const onReplenish = () => {
    api.transferVendorAudit({
      id: detailDataId,
      type: 3,
      remark: ''
    }).then(data => {
      history.push('/csr/vendorIdAudit')
    }).catch()
  }
  //是否算新开业绩
  const getPerformanceTimeStatus = e => {
    setNodeTimeDis(e.target.value == 1 ? false : true)
    if (e.target.value == 0) setPerformanceTimeNode(undefined)
  }
  //是否算新开业绩->是->选择日期
  const getPerformanceTimeNode = e => {
    setPerformanceTimeNode(e)
  }

  return (
    <>
      <div className="pro-detail">
        <h1 style={{ fontWeight: 800, color: '#000'}}>{search.muilt == 0 ? '厂商ID转移审批详情' : search.muilt == 1 ? '批量厂商ID转移审批详情' : '批量客户厂商ID转移审批详情'}</h1>
        <div className="line-box"></div>
        <Descriptions title="审批进度" className="pro-detail-smailtitle"></Descriptions>
        <div className="promotionProcess" >
          <Steps>
            {
              statusList.map((item, index) => {
                let status = 'wait'
                if (item.time && item.time.length > 1) {
                  status = 'process'
                }
                return <Step status={status}
                  key={index}
                  value={item.time}
                  title={item.nodeName}
                  description={
                    <>
                      {item.persons && <div title={`${item.persons.map((item) => item)}`}>{`${item.persons.map((item) => item)}`}</div>}
                      <div title={item.statusName}>{item.statusName}</div>
                      <div title={item.time}>{item.time}</div>
                    </>
                    // `${item.persons.map((item)=>item)} ${item.statusName} ${item.time}`
                  }
                />
              })
            }
          </Steps>
        </div>
        <div className="line-box"></div>

        <Descriptions title="转出信息"
          layout="vertical"
          column={3}
          className="pro-detail-smailtitle pro-apply"
        >
          <dItem label="申请人">
            {outInfoData.userName}
          </dItem>
          <dItem label="直属上级">
            {outInfoData.userLeaderName}
          </dItem>
          <dItem label="所属部门">
            {outInfoData.userDeptName}
          </dItem>
        </Descriptions>

        {/* 转出厂商信息 */}
        {
          outInfoVendorsData.length > 0 && <>
            <div className="line-box"></div>
            <Descriptions
              title="转出厂商信息"
              layout="horizontal"
              className="pro-detail-smailtitle"
            >
            </Descriptions>
            <Table
              dataSource={outInfoVendorsData}
              rowKey="id"
              pagination={false}
              style={{ marginBottom: 20 }}
            >
              <Column title="项目" dataIndex="projectName" />
              <Column title="产品" dataIndex="productName" />
              <Column title="厂商ID" dataIndex="vendor" />
              <Column title="厂商属性" dataIndex="type" render={text => <span>{text == 0 ? '直销' : '代理商'}</span>}/>
              <Column title="OA客户名称" dataIndex="client" />
              <Column title="OAID" dataIndex="clientId" />
              <Column title="关联主体" dataIndex="relBranch" />
              <Column title="关联销售" dataIndex="relSalerName" />
              <Column title="关联客服" dataIndex="relServicerName" />
              <Column title="关联代理商开发" dataIndex="relAgentStaffName" />
              <Column title="创建时间" dataIndex="createTime" />
            </Table>
          </>
        }
       
        {/* 转入信息 */}
        <div className="pro-detail">
          <div className="line-box"></div>
          <Descriptions title="转入信息"
            layout="vertical"
            className="pro-detail-smailtitle pro-apply"
            column={4}
          ></Descriptions>
          <Table
            dataSource={[intoText]}
            rowKey="id"
            pagination={false}
          >
            <Column title="客户类型" dataIndex="intoCustomText" render={(name, record) => 
              <div className={record.intoTypeLabel ? 'intoCustomText' : ''}>{name}</div>
            }/>
            {intoText.client && <Column title="转入OA客户名称" dataIndex="client.client" render={(name, record) => 
              <div className={record.clientLabel ? 'intoCustomText' : ''}>{name}</div>
            }/>}
            {intoText.client && <Column title="转入OAID" dataIndex="client.clientId" render={(name, record) => 
              <div className={record.clientIdLabel ? 'intoCustomText' : ''}>{name}</div>
            }/>}
            {intoText.agent && <Column title="转入代理商名称" dataIndex="agent.agent" render={(name, record) => 
              <div className={record.agentLabel ? 'intoCustomText' : ''}>{name}</div>
            }/>}
            {intoText.agent && <Column title="转入代理商ID" dataIndex="agent.agentId" render={(name, record) => 
              <div className={record.agentIdLabel ? 'intoCustomText' : ''}>{name}</div>
            }/>}
            {intoText.agent && <Column title="转入客户名称" dataIndex="agent.client" render={(name, record) => 
              <div className={record.clientLabel ? 'intoCustomText' : ''}>{name}</div>
            }/>}
            {intoText.agent && <Column title="转入OAID" dataIndex="agent.clientId" render={(name, record) => 
              <div className={record.clientIdLabel ? 'intoCustomText' : ''}>{name}</div>
            }/>}
            {intoText.company && <Column title="转入主体" dataIndex="company.branch" render={(name, record) => 
              <div className={record.branchLabel ? 'intoCustomText' : ''}>{name}</div>
            }/>}
            {intoText.relSaler && <Column title="所属销售" dataIndex="relSaler.staffName" render={(name, record) => 
              <div className={record.relSalerLabel ? 'intoCustomText' : ''}>{name}</div>
            }/>}
            {intoText.relSaler && <Column title="职位" dataIndex="relSaler.jobPositionCn" />}
            {intoText.relSaler && <Column title="所属部门" dataIndex="relSaler.departmentName" />}
            {intoText.relSaler && <Column title="直属上级" dataIndex="relSaler.leaderName" />}
            {intoText.relServicer && <Column title="所属客服" dataIndex="relServicer.staffName" render={(name, record) => 
              <div className={record.relServicerLabel ? 'intoCustomText' : ''}>{name}</div>
            }/>}
            {intoText.relServicer && <Column title="职位" dataIndex="relServicer.jobPositionCn" />}
            {intoText.relServicer && <Column title="所属部门" dataIndex="relServicer.departmentName" />}
            {intoText.relServicer && <Column title="直属上级" dataIndex="relServicer.leaderName" />}
            {intoText.relAgentStaff && <Column title="所属代理商开发" dataIndex="relAgentStaff.staffName" render={(name, record) => 
              <div className={record.relAgentStaffLabel ? 'intoCustomText' : ''}>{name}</div>
            }/>}
            {intoText.relAgentStaff && <Column title="职位" dataIndex="relAgentStaff.jobPositionCn" />}
            {intoText.relAgentStaff && <Column title="所属部门" dataIndex="relAgentStaff.departmentName" />}
            {intoText.relAgentStaff && <Column title="直属上级" dataIndex="relAgentStaff.leaderName" />}
            {intoText.relAgentStaff && <Column title="所属公司" dataIndex="relAgentStaff.companyName" />}
          </Table>
        </div>
        
        <div className="line-box"></div>
        <Descriptions title="其他信息"
          layout="vertical"
          className="pro-detail-smailtitle pro-apply"
        >
          <dItem label="附件" span={3}>
            <>
              {
                showFile && showFile.length >= 1 ? showFile.map((item, i) => {
                  return <div key={i}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
                }) : '无'
              }
            </>
          </dItem>
          {showProfermanceDateNode == true && <dItem label="是否算新开业绩">
            <Radio.Group onChange={getPerformanceTimeStatus} defaultValue={0}>
              <Radio value={1}>是 <DatePicker disabled={nodeTimeDis} value={performanceTimeNode} onChange={getPerformanceTimeNode} /></Radio>
              <Radio value={0}>否</Radio>
            </Radio.Group>
          </dItem>}
          <dItem label="备注">
            <>
              {detailData.remark ? detailData.remark : '无'}
            </>
          </dItem>
        </Descriptions>

        {/* 审批记录 */}
        <div className="line-box"></div>
        <Descriptions
          title="审批记录"
          layout="horizontal"
          className="pro-detail-smailtitle"
        >
        </Descriptions>
        <Table
          dataSource={logConList}
          rowKey="id"
          pagination={false}
        >
          <Column title="审批人" dataIndex="userInfo.staffName" />
          <Column title="审批时间" dataIndex="addTime" />
          <Column title="审批状态" dataIndex="status" />
          <Column title="审批意见" dataIndex="chargeMsg" />
        </Table>
        <div className="btn-setting">
          <Button hidden={!buttonAuthList.replenish} onClick={onReplenish}>补充资料</Button>
          <Button hidden={!buttonAuthList.return_up} onClick={onTurnUp}>转上级</Button>
          <Button hidden={!buttonAuthList.hasChargerPower} onClick={onBtnTurnShow}>驳回</Button>
          <Modal
            // title="确认驳回"
            title={turnNode()}
            visible={turnModalVisible}
            onOk={onTurnApprove}
            onCancel={onTurnCancel}
          >
            <div>
              <span>审批意见:</span>
              <Input.TextArea placeholder="请输入驳回审批意见" value={textAreaVal} onChange={onDescChange} maxLength={100} />
            </div>
            <span className="poine-text" hidden={textDisplay}>请填写审批意见</span>
          </Modal>
          <Button hidden={!buttonAuthList.hasChargerPower} type="primary" onClick={onBtnPassShow}>通过</Button>
          <Modal
            // title="确认通过"
            title={agreeNode()}
            visible={passModalVisible}
            onOk={onApprove}
            onCancel={onPassCancel}
          >
            <div>
              <span>审批意见:</span>
              <Input.TextArea placeholder="请输入通过审批意见" value={pasDesc} onChange={onPassDescChange} maxLength={100} />
            </div>
          </Modal>
        </div>
      </div>
    </>
  )
}

export default VendorIdAuditDetail