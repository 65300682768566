/**
 * 模块名称: 厂商id批量转移
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Form,
  Button,
  Input,
  message,
  Select,
  Row,
  Col,
  Checkbox
} from 'antd'
// import { Link } from 'react-router-dom'

const FormItem = Form.Item
const Option = Select.Option

const VendorBatch = (props) => {
  const { setVendorBatchVisible, setBatchLoading, oaIdVal, historyVal } = props
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form
  const [detailData, setDetailData] = useState({}) // 数据
  const [intoTypeList, setIntoTypeList] = useState([]) // 转出类型
  const [intoTypeOne, setIntoTypeOne] = useState(false) // 控制转入负责人信息展示
  const [staffsArr, setStaffsArr] = useState([]) // 转入员工姓名
  const [intoTypeOneData, setIntoTypeOneData] = useState({})
  const [dataInfo, setDataInfo] = useState({}) // 下一步传入信息

  const [intoTypeTwo, setIntoTypeTwo] = useState(false) // 控制直销客户信息展示
  const [intoTypeTwoData, setIntoTypeTwoData] = useState({})

  const [intoTypeThree, setIntoTypeThree] = useState(false) // 控制转入代理商展示
  const [agentsffArr, setAgentsArr] = useState([]) // 代理商
  const [agentsffId, setAgentsId] = useState('') // 代理商ID
  const [relAgentStaffArr, setRelAgentStaffArr] = useState([]) // 所属渠道
  const [relAgentStaffArrSave, setRelAgentStaffArrSave] = useState({})
  // const [relAgentStaffId, setRelAgentStaffId] = useState('') // 所属渠道id
  const [intoTypeThreeData, setIntoTypeThreeData] = useState({})

  const [intoTypeFour, setIntoTypeFour] = useState(false) // 控制主题信息展示
  const [relBranchArr, setRelBranchArr] = useState([]) // 转入主体
  const [directSelling, setDirectSelling] = useState(true)
  const [relAgentDisabled, setRelAgentDisabled] = useState(true)

  useEffect(() => {
    getBeforeApplie()
  }, [])


  const getBeforeApplie = () => {
    setBatchLoading(true)
    api.getBeforeApplie({ clientId: oaIdVal })
      .then(data => {
        setBatchLoading(false)
        setDetailData(data)
        setDataInfo(data)
        setIntoTypeList(data.intoType)
      })
      .catch(() => setBatchLoading(false))
  }

  // 负责人下一步
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (dataInfo.intoTypeVal == 1 || dataInfo.intoTypeVal == 2 || dataInfo.intoTypeVal == 3) {
        if (vals.checkboxVal == false || vals.checkboxVal == undefined) {
          message.info("确认信息为必选项")
          return
        }
      }
      if (!err) {
        historyVal.push('/csr/customer/vendorBatchAdd', { data: dataInfo })
        setVendorBatchVisible(false)
      }
    })
  }

  // const formItemLayout = {
  //   labelCol: {
  //     xs: { span: 24 },
  //     sm: { span: 8 },
  //   },
  //   wrapperCol: {
  //     xs: { span: 24 },
  //     sm: { span: 16},
  //   }
  // }
  const onSelect = (intoTypeVal) => {
    setDataInfo({})
    setIntoTypeOneData({})
    setIntoTypeTwoData({})
    setDataInfo({ ...dataInfo, intoTypeVal })
    if (intoTypeVal == 0) {
      api.getTransferIntoTypeInfo({ intoType: 0, vendor: '', clientId: detailData.clientId })
        .then(data => {
          setIntoTypeOne(true)
          setStaffsArr(data.staffs)
        })
        .catch()
    } else {
      setIntoTypeOne(false)
    }

    if (intoTypeVal == 1) {
      setIntoTypeTwo(true)
    } else {
      setIntoTypeTwo(false)
    }

    if (intoTypeVal == 2) {
      api.getTransferIntoTypeInfo({ intoType: 2, vendor: '', clientId: detailData.clientId })
        .then(data => {
          setIntoTypeThree(true)
          setRelAgentStaffArrSave(data.relAgentStaff)
          setAgentsArr(data.agents)
        })
        .catch()
    } else {
      setIntoTypeThree(false)
    }

    if (intoTypeVal == 3) {
      api.getTransferIntoTypeInfo({ intoType: 3, vendor: '', clientId: detailData.clientId })
        .then(data => {
          setIntoTypeFour(true)
          setRelBranchArr(data.relBranch)
        })
        .catch()
    } else {
      setIntoTypeFour(false)
    }
  }
  
  const onKeyup = (e) => {
    if (e.keyCode === 13) {
      let oaVal = e.target.value
      if (e.target.value.trim() !== '') {
        api.getTransferSelectInfo({
          intoType: 1,
          vendor: '',
          clientId: detailData.clientId,
          id: e.target.value,
        }).then(data => {
          setDirectSelling(false)
          setIntoTypeTwoData(data)
          setDataInfo({ ...dataInfo, dataCopy: {...data}, oaVal })
        }).catch(err => {
          setIntoTypeTwoData({})
          setDirectSelling(true)
        })
      } else {
        setIntoTypeTwoData({})
      }
    }
  }

  const onOaIdChangeTwo = (e) => {
    // if (e.target.value.trim() !== '') {
      setDirectSelling(true)
      setIntoTypeTwoData({})
    // } 
  }

  // 输入oa获取名称
  const onOaIdChange = (e) => {
    let oaVal = e.target.value
    if (e.target.value.trim() !== '') {
      api.getTransferSelectInfo({
        intoType: 1,
        vendor: '',
        clientId: detailData.clientId,
        id: e.target.value
      }).then(data => {
        setDirectSelling(false)
        setIntoTypeTwoData(data)
        setDataInfo({ ...dataInfo, dataCopy: {...data}, oaVal })
      }).catch(err => {
        setIntoTypeTwoData({})
        setDirectSelling(true)
      })
    } else {
      setIntoTypeTwoData({})
    }
  }

  // 选择员工姓名后
  const onSelectComp = (e, val) => {
    api.getTransferSelectInfo({
      intoType: 0,
      vendor: '',
      id: e,
      clientId: detailData.clientId
    }).then(data => {
      setIntoTypeOneData(data)
      setDataInfo({ ...dataInfo, ...data, ...val.props })
    }).catch()
  }

  // 选择主体后
  const onSelectrelBranch = (e, val) => {
    // 仅获取厂商列表
    api.getTransferSelectInfo({
      intoType: 3,
      vendor: '',
      id: e,
      clientId: detailData.clientId
    }).then(data => {
      setDataInfo({ ...dataInfo, ...data, ...val.props })
    }).catch()
  }

  // 选择代理商后
  const onAgentsff = (e, val) => {
    setFieldsValue({
      relAgentStaffName: undefined
    })
    setIntoTypeThreeData({})
    setAgentsId(e)
    setDataInfo({ ...dataInfo, agentData: { ...val.props } })
    setRelAgentDisabled(false)

    for (let key in relAgentStaffArrSave) {
      if(+key === +e) {
        setRelAgentStaffArr(relAgentStaffArrSave[key])
      }
    }
    // let vals = {}
    // vals.agent = e ? e : ''
    // vals.relAgentStaff =  relAgentStaffId ? relAgentStaffId : ''
    // setAgentsId(e)
    // api.getTransferSelectInfo({
    //   intoType: 2,
    //   vendor: detailData.vendor,
    //   id: JSON.stringify(vals)
    // }).then(data => {
    //   console.log("data111",data)
    //   // setIntoTypeOneData(data)
    //   // setDataInfo({ ...dataInfo, ...data, ...val.props })
    // }).catch()
  }

  // 选择渠道后
  const onRelAgentStaffArr = (e, val) => {
    let vals = {}
    vals.relAgentStaff = e ? e : ''
    vals.agent = agentsffId ? agentsffId : ''
    // setRelAgentStaffId(e)
    api.getTransferSelectInfo({
      intoType: 2,
      vendor: '',
      id: JSON.stringify(vals),
      clientId: detailData.clientId,
    }).then(data => {
      setIntoTypeThreeData(data.relAgentStaff)
      setDataInfo({ ...dataInfo, ...data, staffData: { ...val.props } })
    }).catch(err => {
      setFieldsValue({
        relAgentStaffName: ''
      })
    })
  }

  return (
    <>
      <div className="vendorstry idTransfer">
        <Form onSubmit={formSubmit}>
         <Row gutter={24}>
              <Col span={8}>
                <FormItem label="客户名称">
                  <>{detailData.clientName}</>
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="OAID">
                  <>{detailData.clientId}</>
                </FormItem>
              </Col>
              <Col span={10}>
              <FormItem label="转移至">
                {getFieldDecorator('industry', {
                  rules: [{ required: true, message: '请选择' }]
                })(
                  <Select placeholder="请选择"
                    style={{ width: 200 }}
                    dropdownMatchSelectWidth={false}
                    onSelect={onSelect}
                  >
                    {intoTypeList && intoTypeList.map((item, index) => <Option key={index} value={index}>{item}</Option>)}
                  </Select>
                )}
              </FormItem>
            </Col>
            </Row> 
          <Row gutter={24}>
            {intoTypeOne == true ?
              <>
                <Col span={8}>
                  <FormItem label="转入员工姓名">
                    {getFieldDecorator('staffName', {
                      rules: [{ required: true, message: '请输入转入员工姓名' }]
                    })(
                      <Select placeholder="请输入转入员工姓名"
                        style={{ width: 200 }}
                        //  filterOption={true}
                        onSelect={onSelectComp}
                        //  onSearch={nameSelect}
                        //  autoFocus
                        showSearch={true}
                        optionFilterProp="children">
                        {staffsArr && staffsArr.map(item => <Option key={item.id} value={item.id}>{item.staffName}</Option>)}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label="职位">
                    <>{intoTypeOneData.jobPositionCn}</>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label="所属部门">
                    <>{intoTypeOneData.departmentName}</>
                  </FormItem>
              </Col>
              </>
              : null
            }
            {intoTypeThree == true ?
              <>
                <Col span={8}>
                  <FormItem label="转入代理商名称">
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: '请选择转入代理商名称' }]
                    })(
                      <Select placeholder="请选择转入代理商名称"
                        style={{ width: 200 }}
                        onSelect={onAgentsff}
                        showSearch={true}
                        optionFilterProp="children">
                        {agentsffArr && agentsffArr.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label="代理商ID">
                    <>{agentsffId ? agentsffId : ''}</>
                  </FormItem>
                </Col>
                <Col span={10}>
                <FormItem label="渠道所属开发">
                  {getFieldDecorator('relAgentStaffName', {
                    rules: [{ required: true, message: '请选择渠道所属开发' }]
                  })(
                    <Select placeholder="请选择渠道所属开发"
                      style={{ width: 200 }}
                      onSelect={onRelAgentStaffArr}
                      showSearch={true}
                      disabled={relAgentDisabled}
                      optionFilterProp="children">
                      {relAgentStaffArr && relAgentStaffArr.map(item => <Option key={item.relAgentStaff} value={item.relAgentStaff}>{item.relAgentStaffName}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              </>
              : null
            }
            {intoTypeTwo == true ?
              <>
                <Col span={8}>
                  <FormItem label="转入OAID">
                    {getFieldDecorator('OAID', {
                      rules: [{ required: true, message: '请输入转入OAID' }]
                    })(
                      <Input onChange={onOaIdChangeTwo} onKeyUp={onKeyup} onBlur={onOaIdChange} style={{ width: 200 }} placeholder="请输入转入OAID" />
                    )}
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label="转入客户名称">
                    <>{intoTypeTwoData.clientName}</>
                  </FormItem>
                </Col>
              </>
              : null
            }
            {intoTypeFour == true ?
              <>
                <Col span={8}>
                  <FormItem label="转入主体">
                    {getFieldDecorator('staffName', {
                      rules: [{ required: true, message: '请选择转入主体' }]
                    })(
                      <Select placeholder="请选择转入主体"
                        style={{ width: 200 }}
                        onSelect={onSelectrelBranch}
                        showSearch={true}
                        optionFilterProp="children">
                        {relBranchArr && relBranchArr.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </>
              : null
            }

          </Row>
          {intoTypeOne == true ?
            <Row gutter={24}>
              <Col span={8}>
                <FormItem label="直接主管">
                  <>{intoTypeOneData.leaderName}</>
                </FormItem>
              </Col>
            </Row> : null
          }
          {
            intoTypeTwo == true ?
              <Row gutter={24}>
                <Col span={24}>
                  <FormItem>
                    {getFieldDecorator('checkboxVal', {
                      valuePropName: 'checked',
                      initialValue: false,
                      rules: [{ required: true, message: '请确认' }]
                    })(
                      <Checkbox style={{ color: "red" }}>请确认厂商ID转出后，该厂商ID在原客户下的消耗不能退单</Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>
              : null
          }
          {intoTypeThree == true ?
            <Row gutter={24}>
              <Col span={8}>
                <FormItem label="职位">
                  <>{intoTypeThreeData.jobPositionCn}</>
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="所属部门">
                  <>{intoTypeThreeData.departmentName}</>
                </FormItem>
              </Col>
              <Col span={10}>
                <FormItem label="直属上级">
                  <>{intoTypeThreeData.leaderName}</>
                </FormItem>
              </Col>
            </Row> : null
          }
          {intoTypeThree == true ?
            <Row gutter={24}>
              <Col span={8}>
                <FormItem label="所属公司">
                  <>{intoTypeThreeData.companyName}</>
                </FormItem>
              </Col>
            </Row> : null
          }
          {
            intoTypeThree == true ?
              <Row gutter={24}>
                <Col span={24}>
                  <FormItem>
                    {getFieldDecorator('checkboxVal', {
                      valuePropName: 'checked',
                      initialValue: false,
                      rules: [{ required: true, message: '请确认' }]
                    })(
                      <Checkbox style={{ color: "red" }}>请确认厂商ID转出后，该厂商ID在原客户下的消耗不能退单</Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>
              : null
          }
          {
            intoTypeFour == true ?
              <Row gutter={24}>
                <Col span={24}>
                  <FormItem>
                    {getFieldDecorator('checkboxVal', {
                      valuePropName: 'checked',
                      initialValue: false,
                      rules: [{ required: true, message: '请确认' }]
                    })(
                      <Checkbox style={{ color: "red" }}>请确认厂商ID转出后，该厂商ID在原客户下的消耗不能退单</Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>
              : null
          }
          <div style={{ height: 60 }}></div>
          <div className="setVendorstryBtn">
            <Button onClick={() => { setVendorBatchVisible(false) }}>取消</Button>
            {/* <Link to={`/csr/customer/vendorBatchAdd?id=${1}`}> */}
            <Button type="primary" disabled={intoTypeTwo == true && directSelling ? true: false}  style={{ marginLeft: 20 }} htmlType="submit">下一步</Button>
            {/* </Link> */}
          </div>
        </Form>
      </div>
    </>
  )
}
export default Form.create()(VendorBatch)
