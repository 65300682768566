/**
 * 模块名称: 客户管理-折扣列表
 */

import React, {useEffect, useState} from 'react'
import api from '@/api'
import {
    Form,
    Input,
    Row, Col, Select, Button, Table
} from 'antd'
import {parseSearch} from "../../../../utils";

const Column = Table.Column
const FormItem = Form.Item
const Option = Select.Option
let pageSize = 10
let currentPage = 1

let oaqId = ''
let projectId = ''
let productId = ''
let industryOne = ''
let industryTwo = ''
let discountType = ''
let searchParams = {
    oaqId:oaqId,
    projectId:projectId,
    productId:productId,
    industryOne:industryOne,
    industryTwo:industryTwo,
    discountType:discountType
}

let discountTypeList = [
    {id:'0', name:'新开'},
    {id:'1', name:'续费'},
]

const DiscountList = (props) => {
    const [list, setList] = useState({})
    const [loading, setLoading] = useState(false)
    const {getFieldDecorator, validateFields} = props.form
    const search = parseSearch(props.location.search)

    useEffect(() => {
        currentPage = search.page ? +search.page : 1
        pageSize = search.limit ? +search.limit : 10
        searchParams = {
            limit: pageSize, 
            page: currentPage,
            oaqId:search.id,
            type:0, //客户类型 （0直销 1代理商）
            projectId:search.projectId,
            productId:search.productId,
            industryOne:search.industryOne,
            industryTwo:search.industryTwo,
            discountType:search.discountType
        }
      getList()
    }, [])

    const getList = () => {
        setLoading(true)
        if(oaqId.length)searchParams.oaqId = oaqId
        if(projectId.length)searchParams.projectId = projectId
        if(productId.length)searchParams.productId = productId
        if(industryOne.length)searchParams.industryOne = industryOne
        if(industryTwo.length)searchParams.industryTwo = industryTwo
        if(discountType.length)searchParams.discountType = discountType
        api.getDiscountList({...searchParams, limit: pageSize, page: currentPage}).then(
            res => {
                //console.log(res)
                setList(res)
            }
        ).finally(() => {
            setLoading(false)
        })
    }


    // 查询
    const onSearch = (e) => {
        e.preventDefault()
        validateFields((err, vals) => {
            if (!err) {
                searchParams.project = vals.projectId
                searchParams.product = vals.productId
                searchParams.industryOne = vals.industryOne
                searchParams.industryTwo = vals.industryTwo
                searchParams.discountType = vals.discountType
                currentPage = 1
                getList()
            }
        })
    }

    const onChangeTable = (pagination) => {
        currentPage = pagination.current
        pageSize = pagination.pageSize
        getList()
    }

    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        }
    }

    return (
        <div className='agent-ziji'>
            <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
                <div className="search-item-wrap">
                    <div className="search-item">
                        <Row gutter={10}  >
                            <Col span={8}>
                                <FormItem label="项目" style={{textAlign:"center"}}> {getFieldDecorator('projectId')(<Input   placeholder="请输入"  />)} </FormItem>  
                            </Col>
                            <Col span={8}>
                                <FormItem label="产品" style={{textAlign:"center"}}> {getFieldDecorator('productId')(<Input   placeholder="请输入"  />)} </FormItem>  
                            </Col>
                            <Col span={8}>
                                <FormItem label="一级行业" style={{textAlign:"center"}}> {getFieldDecorator('industryOne')(<Input   placeholder="请输入"  />)} </FormItem>  
                            </Col>
                            <Col span={8}>
                                <FormItem label="二级行业" style={{textAlign:"center"}}> {getFieldDecorator('industryTwo')(<Input   placeholder="请输入"  />)} </FormItem>  
                            </Col>
                            <Col span={8}>
                                <FormItem label="折扣类型" style={{textAlign:"center"}}> 
                                {getFieldDecorator('discountType')(
                                    <Select placeholder="请选择">
                                        {discountTypeList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                    </Select>
                                 )}
                                </FormItem>  
                            </Col>
                            <Col span={8} className="search-buttons">
                                <Button type="primary" style={{marginTop:5}} htmlType="submit" icon="search"> 查询</Button>
                            </Col>
                        </Row>
                    </div>
                </div> 
            </Form>
            <Table
                dataSource={list.list}
                rowKey="id"
                className="table-page"
                loading={loading}
                pagination={{
                    pageSize,
                    total: list.count,
                    current: currentPage,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '50', '100', '500', '1000', '2000'],
                    showTotal: total => {
                        let totalPages = Math.ceil(total/pageSize)
                        return `共 ${total} 条记录，第 ${currentPage}/${totalPages} 页`
                    }
                }}
                onChange={onChangeTable}
            >
                <Column title="序号" dataIndex="sn"/>
                <Column title="折扣" dataIndex="discount"/>
                <Column title="项目" dataIndex="project"/>
                <Column title="产品" dataIndex="product"/>
                <Column title="一级行业" dataIndex="one_industry"/>
                <Column title="二级行业" dataIndex="two_industry"/>
                <Column title="折扣类型" dataIndex="typeName" />
            </Table>
        </div>
    )
}

export default Form.create()(DiscountList)