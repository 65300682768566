/**
 * 模块名称: 厂商id(批量)转移提交
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Button,
  Input,
  Icon,
  message,
  Descriptions,
  Upload,
} from 'antd'
import { useSelector } from 'react-redux'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'

const { Column } = Table
const FormItem = Form.Item
const { Dragger } = Upload

const VendorBatchAdd = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form
  const { history, location } = props
  const dataVal = props.location.state ? props.location.state.data : null
  const [fileList, setFileList] = useState([])
  const draggerRef = useRef(null)
  const userInfo = useSelector(state => state.userInfo)

  const [editDataVal, setEditDataVal] = useState(null)
  const search = parseSearch(location.search)
  const [outInfoData, setOutInfoData] = useState(null) // 转出数据
  const [selectInfoData, setSelectInfoData] = useState([]) // 所有厂商数据
  const [checkedIdArr, setCheckedIdArr] = useState([]) // 选中id


  useEffect(() => {
    // 驳回重提返显(编辑)
    if (search.editId) {
      api.getTransferDirectVendorDetail({ id: search.editId, edit: 1 }, true).then(data => {
        setEditDataVal(data)
        setOutInfoData(data.outInfo)
        let vendorsArr = []
        data.outInfo.vendors.map((item, i) => {
          // console.log("item",item)
          vendorsArr.push(item.id)
          setCheckedIdArr(vendorsArr)
        })
        setSelectInfoData(data.getSelectInfo)
        if (data.files && data.files.length) {
          data.files.forEach((item, index) => {
            fileList.push({
              status: 'done',
              uid: index,
              name: item.name,
              url: item.url
            })
          })
          setFileList(data.files.map((item, index) => ({...item, uid: index})))
        }
        setFieldsValue({
          remark: data.remark
        })
      })
    }
  }, [])

  // 文件上传 
  const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.gif, .png, .jpg, .jpeg, .PDF, .zip',
		allowSizeType: 2
  })
  
  const ongoback = () => {
    history.goBack()
  }

  //提交表单
  const formSubmit = (e) => {
    e.preventDefault()
    if (checkedIdArr.length <= 0) {
      message.warning("请选择转出厂商")
      return
    }
    validateFields((err, vals) => {
      let params = Object.assign({}, vals)
      let uploadList = []
      for (let i = 0, k = fileList.length; i < k; i++) {
        if (fileList[i].status !== 'removed') {
          uploadList.push({
            url: fileList[i].url,
            name: fileList[i].name
          })
        }
      }

      let intoInfoVal = {}
      intoInfoVal.staffId = dataVal && dataVal.value ? dataVal.value : editDataVal && editDataVal.intoInfo.staffId
      intoInfoVal.staffName = dataVal && dataVal.children ? dataVal.children : editDataVal && editDataVal.intoInfo.staffName

      let intoInfoValTwo = {}
      intoInfoValTwo.clientId = dataVal ? dataVal.oaVal : editDataVal && editDataVal.intoInfo.clientId

      let intoInfoValaThree = {}
      intoInfoValaThree.agent = dataVal && dataVal.agentData ? dataVal.agentData.value : editDataVal && editDataVal.intoInfo.agentId
      intoInfoValaThree.agentName = dataVal && dataVal.agentData ? dataVal.agentData.children : editDataVal && editDataVal.intoInfo.agentName
      intoInfoValaThree.relAgentStaff = dataVal && dataVal.staffData ? dataVal.staffData.value : editDataVal && editDataVal.intoInfo.staffId
      intoInfoValaThree.relAgentStaffName = dataVal && dataVal.staffData ? dataVal.staffData.children : editDataVal && editDataVal.intoInfo.staffName

      let intoInfoValFour = {}
      intoInfoValFour.branchId = dataVal && dataVal.value ? dataVal.value : editDataVal && editDataVal.intoInfo.branchId

      let outInfoVal = {}
      outInfoVal.clientId = dataVal ? dataVal.clientId : outInfoData && outInfoData.clientId
      outInfoVal.vendors = checkedIdArr.join(',')

      let intoInfo = {}
      if (dataVal ? dataVal.intoTypeVal == 0 : editDataVal && editDataVal.intoType == 0) {
        intoInfo.intoInfo = intoInfoVal
      } else if (dataVal ? dataVal.intoTypeVal == 1 : editDataVal && editDataVal.intoType == 1) {
        intoInfo.intoInfo = intoInfoValTwo
      } else if (dataVal ? dataVal.intoTypeVal == 2 : editDataVal && editDataVal.intoType == 2) {
        intoInfo.intoInfo = intoInfoValaThree
      } else if (dataVal ? dataVal.intoTypeVal == 3 : editDataVal && editDataVal.intoType == 3) {
        intoInfo.intoInfo = intoInfoValFour
      }
      intoInfo.outInfo = outInfoVal

      params.intoInfo = intoInfo
      params.files = uploadList
      params.muilt = 1
      params.intoType = dataVal ? dataVal.intoTypeVal : editDataVal && editDataVal.intoType
      if (search.editId) params.id = search.editId
      if (!search.editId) params.id = ''

      if (!err) {
        api.saveTransferDirectVendor(params).then(res => {
          // console.log("res", res)
          message.success("提交成功")
          history.goBack()
        }).catch(() => {
        })
      }
    })
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      // setSelectedCount(selectedRows.length)
      setCheckedIdArr(selectedRowKeys)
    },
    selectedRowKeys: checkedIdArr
  }

  return (
    <>
      {/* 公共转出信息 */}
      <div className="pro-detail">
        <h3>批量厂商ID转移</h3>
        <div className="line-box"></div>
        <Descriptions title="转出信息"
          layout="vertical"
          className="pro-detail-smailtitle pro-apply"
          column={4}
        >
          <dItem label="转出客户名称">
            {
              dataVal ? dataVal.clientName : outInfoData && outInfoData.client}
          </dItem>
          <dItem label="转出OAID">
            {dataVal ? dataVal.clientId : outInfoData && outInfoData.clientId}
          </dItem>
          <dItem label="申请人">
            {dataVal ? userInfo.staffName : outInfoData && outInfoData.userName}
          </dItem>
          <dItem label="直属上级">
            {dataVal ? userInfo.leaderName : outInfoData && outInfoData.userLeaderName}
          </dItem>
          <dItem label="所属部门">
            {dataVal ? userInfo.departmentName : outInfoData && outInfoData.userDeptName}
          </dItem>
        </Descriptions>
      </div>

      {/* 负责人转入信息 */}
      { (dataVal ? dataVal.intoTypeVal == 0 : editDataVal && editDataVal.intoType == 0) ?
        <div className="pro-detail">
          <div className="line-box"></div>
          <Descriptions title="转入信息"
            layout="vertical"
            className="pro-detail-smailtitle pro-apply"
            column={4}
          >
            <dItem label="转入员工姓名">
              {dataVal ? dataVal.children : editDataVal && editDataVal.intoInfo.staffName}
            </dItem>
            <dItem label="职位">
              {dataVal ? dataVal.jobPositionCn : editDataVal && editDataVal.intoInfo.jobPositionCn}
            </dItem>
            <dItem label="所属部门">
              {dataVal ? dataVal.departmentName : editDataVal && editDataVal.intoInfo.departmentName}
            </dItem>
            <dItem label="直接主管">
              {dataVal ? dataVal.leaderName : editDataVal && editDataVal.intoInfo.leaderName}
            </dItem>
          </Descriptions>
          <div className="line-box"></div>
        </div> : null
      }
      {/* 转入直销客户 */}
      { (dataVal ? dataVal.intoTypeVal == 1 : editDataVal && editDataVal.intoType == 1) ?
        <div className="pro-detail">
          <div className="line-box"></div>
          <Descriptions title="转入信息"
            layout="vertical"
            className="pro-detail-smailtitle pro-apply"
            column={4}
          >
            <dItem label="转入客户名称">
              {dataVal ? dataVal.dataCopy.clientName : editDataVal && editDataVal.intoInfo.client}
            </dItem>
            <dItem label="转入OAID">
              {dataVal ? dataVal.oaVal : editDataVal && editDataVal.intoInfo.clientId}
            </dItem>
          </Descriptions>
          <div className="line-box"></div>
        </div> : null
      }
      {/* 转入代理商 */}
      { (dataVal ? dataVal.intoTypeVal == 2 : editDataVal && editDataVal.intoType == 2) ?
        <div className="pro-detail">
          <div className="line-box"></div>
          <Descriptions title="转入信息"
            layout="vertical"
            className="pro-detail-smailtitle pro-apply"
            column={4}
          >
            <dItem label="转入代理商名称">
              {dataVal&&dataVal.agentData ? dataVal.agentData.children : editDataVal && editDataVal.intoInfo.agentName}
            </dItem>
            <dItem label="转入代理商ID">
              {dataVal&&dataVal.agentData ? dataVal.agentData.value : editDataVal && editDataVal.intoInfo.agentId}
            </dItem>
            <dItem label="渠道所属开发">
              {dataVal ? dataVal.staffData.children : editDataVal && editDataVal.intoInfo.staffName}
            </dItem>
            <dItem label="职位">
              {dataVal ? dataVal.relAgentStaff.jobPositionCn : editDataVal && editDataVal.intoInfo.jobPositionCn}
            </dItem>
            <dItem label="所属部门">
              {dataVal ? dataVal.relAgentStaff.departmentName : editDataVal && editDataVal.intoInfo.departmentName}
            </dItem>
            <dItem label="直属上级">
              {dataVal ? dataVal.relAgentStaff.leaderName : editDataVal && editDataVal.intoInfo.leaderName}
            </dItem>
            <dItem label="所属公司">
              {dataVal ? dataVal.relAgentStaff.companyName : editDataVal && editDataVal.intoInfo.companyName}
            </dItem>
          </Descriptions>
          <div className="line-box"></div>
        </div> : null
      }
      {/* 转入主体 */}
      { (dataVal ? dataVal.intoTypeVal == 3 : editDataVal && editDataVal.intoType == 3) ?
        <div className="pro-detail">
          <div className="line-box"></div>
          <Descriptions title="转入信息"
            layout="vertical"
            className="pro-detail-smailtitle pro-apply"
            column={4}
          >
            <dItem label="转入主体">
              {dataVal ? dataVal.children : editDataVal && editDataVal.intoInfo.branch}
            </dItem>
          </Descriptions>
          <div className="line-box"></div>
        </div> : null
      }

      <div className="pro-detail">
        <h4 style={{ marginTop: 12 }}>转出厂商信息</h4>
        <div className="customerLine"></div>
        <Table
          dataSource={dataVal ? (dataVal.dataCopy ? dataVal.dataCopy.vendors : dataVal.vendors) : selectInfoData}
          rowKey="id"
          className="table-BatchAdd"
          // loading={loading}
          // pagination={false}
          // scroll={{ x: 1400 }}
          // onChange={onChangeTable}
          rowSelection={rowSelection}
        >
          <Column title="项目" dataIndex="projectName" />
          <Column title="产品" dataIndex="productName" />
          <Column title="厂商ID" dataIndex="vendor" />
          <Column title="OA客户名称" dataIndex="client" />
          <Column title="OAID" dataIndex="clientId" />
          <Column title="关联主体" dataIndex="relBranch" />
          <Column title="关联销售" dataIndex="relSalerName" />
          <Column title="关联客服" dataIndex="relServicerName" />
          <Column title="创建时间" dataIndex="createTime" />
        </Table>
        <div className="line-box"></div>
      </div>

      {/* 其他信息 */}
      <div className="customerSty">
        <h4 style={{ marginTop: 12 }}>其他信息</h4>
        <div className="customerLine"></div>
        <Form onSubmit={formSubmit}>
          <div>
            <FormItem label="附件">
              <div className="dragger-box" ref={draggerRef}>
                <Dragger
                  {...uploadFiles}
                >
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                  <p className="ant-upload-hint">
                    附件支持格式：GIF、PNG、JPG、PDF、zip  支持添加多个附件，单个附件大小不超过5M，文件数量不超过5个。
                </p>
                </Dragger>
              </div>
            </FormItem>
            <FormItem label="备注">
              {getFieldDecorator('remark')(
                <Input.TextArea placeholder="请输入备注" maxLength={100} />
              )}
            </FormItem>
            <div className="bizOrder-btn">
              <Button onClick={ongoback} style={{ marginRight: 20 }}>取消</Button>
              <Button style={{ marginRight: 260 }} type="primary" htmlType="submit">提交</Button>
            </div>
          </div>
        </Form>
      </div>
    </>
  )
}
export default Form.create()(VendorBatchAdd)
