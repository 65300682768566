/**
 * 模块名称: 客户管理模块
 * @author zhuyan@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Customer from './CsrManagement'
import AddCustomerAudit from './AddCustomerAudit'
import ClientTransfer from './ClientTransfer'
import VendorIdAudit from './VendorIdAudit'
import FirmList from './FirmList'
import CreditStatistics from './CreditStatistics'

import './assets/style.scss'

const CustomerIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/customer'} component={Customer} />
      <Route path={match.path + '/addAudit'} component={AddCustomerAudit} />
      <Route path={match.path + '/turnAudit'} component={ClientTransfer} />
      <Route path={match.path + '/vendorIdAudit'} component={VendorIdAudit} />
      <Route path={match.path + '/firmList'} component={FirmList} />
      <Route path={match.path + '/accountNumber'} component={CreditStatistics} />
      <Route component={Customer} />
    </Switch>
  )
}

export default CustomerIndex