/**
 * 模块名称: 合同列表
 * @author lids@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Input,
  Button,
  Alert,
  Row, Col,
} from 'antd'
import { parseSearch } from '@/utils'

const { Column } = Table
const FormItem = Form.Item

let pageSize = 10
let currentPage = 1
let corpId = []
let searchParamsTmp = {
  limit: pageSize, 
  page: currentPage,
  corpId: corpId,
  needApplyId: true
}
let storeFilterName = ''
let storeFilterContent = {}

const ContractList = (props) => {
  const { history, match, location } = props

  const { getFieldDecorator, validateFields } = props.form

  const [count, setCount] = useState(0)
  const [list, setList] = useState([])
  const [filter, setFilter] = useState({})
  
  const [loading, setLoading] = useState(false)

  const search = parseSearch(location.search)
  
  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    searchParamsTmp = {
      limit: pageSize, 
      page: currentPage,
      customerId:search.id,
      needApplyId: true
    }
    storeFilterName = ''
    storeFilterContent = {}
    getContractList()
  },[])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    }
  } 

  //分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    searchParamsTmp.page = currentPage = pagination.current
    searchParamsTmp.limit = pageSize = pagination.pageSize
    //history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getContractList()
  }

  //获取列表
  const getContractList = () => {
    setLoading(true)
    if(corpId.length)searchParamsTmp.corpId = corpId
      api.getFortuneContractList({
        ...searchParamsTmp,
        flag: 1
      }).then(res => {
      setCount(res.count)
      let list = res.list 
      // console.log(list, 12)
      setList(list)
      setFilter(res.filter)
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }
 

  //数据转换
  const transData = (vals) => {
    if(vals !== undefined){
      for(let i in vals){
        if(vals[i] !== undefined){
           searchParamsTmp[i] = vals[i]
        }
      }
      // console.log('searchParamsTmp=>', searchParamsTmp)
    }
  }
  //点击筛选
  const searchSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      //console.log(vals)
      transData(vals)
      if (!err) {
        searchParamsTmp.page = currentPage = 1 
        getContractList() 
      }
    })
    
  } 

  return (
    <> 
      <div className="search-askforleave-wrap"> 
        <Form onSubmit={searchSubmit} {...formItemLayout} > 
          <div className="search-item-wrap">
                <div className="search-item">
                    <Row gutter={10}  >
                        <Col span={6}>
                            <FormItem label="合同号" style={{textAlign:"center"}}> {getFieldDecorator('contract_no')(<Input placeholder="请输入合同号查询" />)} </FormItem>  
                        </Col>
                        <Col span={5} className="search-buttons">
                            <Button type="primary" style={{marginTop:5}} htmlType="submit" icon="search"> 查询</Button>
                        </Col>
                    </Row>
                </div>
          </div>  
        </Form>
      </div>
      <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        dataSource={list}
        rowKey="id"
        loading={loading}
        className="askforleave-table"
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        onChange={onChangeTable} 
      > 
        <Column title="合同号" dataIndex="contractNo" /> 
        <Column title="申请时间" dataIndex="applyDate" />
        <Column title="有效期" dataIndex="endTime" />
        <Column title="申请人" dataIndex="applyName" /> 
      </Table>
    </>
  )
}

export default Form.create()(ContractList)