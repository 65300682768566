/**
 * 模块名称: 客户名称查询
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Button,
  message,
} from 'antd'

const { Column } = Table

let filter = {
  name: '',
}

const NameQuery = (props) => {
  const { setNameVisible, setNameLoading } = props
  const [nameList, setNameList] = useState([])

  useEffect(() => {
    filter = {
      name: '',
    }
    // getFindClientName()
  }, [])


  const getFindClientName = () => {
    setNameLoading(true)
    api.getFindClientName({ name: filter.name })
      .then(data => {
        setNameLoading(false)
        setNameList(data)
      })
      .catch(() => setNameLoading(false))
  }

  const departInp = useRef(null)
  const onSearch = () => {
    filter.name = departInp.current.value
    if (departInp.current.value == '') {
      message.info("请输入客户名称")
      return
    }
    getFindClientName()
  }

  const onKeyup = (e) => {
    if (e.keyCode === 13) {
      onSearch()
    }
  }

  return (
    <>
      <div className="vendorstry">
        <div className="vendorwrap">
          <div className="vendor-item">
            <div style={{ width: 100 }}>客户名称: </div>
            <input className="ant-input" ref={departInp} type="text" placeholder="请输入客户名称" onKeyUp={onKeyup} />
          </div>
          <Button onClick={onSearch} type="primary">查询</Button>
        </div>
        <Table
          dataSource={nameList}
          rowKey="id"
          className="table-page"
          pagination={false}
        >
          <Column title="OAID" dataIndex="oaId" />
          <Column title="客户名称" dataIndex="clientName" />
          <Column title="关联代理商" dataIndex="agentName" />
          <Column title="代理商ID" dataIndex="qId" />
          <Column title="创建时间" dataIndex="addTime" />
        </Table>

        <div style={{ height: 60 }}></div>
        <div className="setVendorstryBtn">
          <Button type="primary" onClick={() => { setNameVisible(false) }}>关闭</Button>
        </div>
      </div>
    </>
  )
}

export default NameQuery