/**
 * 模块名称: 客户管理-厂商信息列表
 */

import React, { useEffect, useState } from 'react'
import api from '@/api'
import {
  Form,
  Input,
  Drawer,
  Spin,
  Row,
  Col,
  Button,
  Table,
  message
} from 'antd'
import { parseSearch } from "../../../../utils"
import VendorTransfer from './VendorTransfer'

const Column = Table.Column
const FormItem = Form.Item
let pageSize = 10
let currentPage = 1
let oaId = ''
let vendorId = ''
let searchParams = {
  oaId: oaId,
  vendorId: vendorId,
}

const VendorInfoList = (props) => {
  const { history, location } = props
  // console.log(location)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const { getFieldDecorator, validateFields } = props.form
  const [outloading, setOutLoading] = useState(false) // 厂商ID转出Loading
  const [outVisible, setOutVisible] = useState(false) // 厂商ID转出
  const [oaIdVal, setoaIdVal] = useState('') // oaID
  const [vendorVal, setvendorIdVal] = useState('') // 厂商id

  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    searchParams = {
      limit: pageSize,
      page: currentPage,
      oaId: search.id
    }
    getList()
  }, [])


  // 请求接口获取厂商信息列表
  const getList = () => {
    setLoading(true)
    if (oaId.length) searchParams.oaId = oaId
    if (vendorId.length) searchParams.vendorId = vendorId
    api.getVendorInfoList({ ...searchParams, limit: pageSize, page: currentPage }).then(
      res => {
        //console.log(res)
        setList(res)
      }
    ).finally(() => {
      setLoading(false)
    })
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        searchParams.vendorId = vals.vendorId
        currentPage = 1
        getList()
      }
    })
  }

  // const onToggle = () => {
  //     setExpand(!expand)
  // }

  const onChangeTable = (pagination) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    getList()
  }
  const onChangeTab = (key) => {
    //currentType = key - 1;
    currentPage = 1;
    getList()
  }

  // 厂商id转出弹窗
  const onOut = (val) => {
    // setoaIdVal(val.clientId)
    // setvendorIdVal(val.vendor)
    // setOutVisible(true)
    history.push('/csr/customer/newVendor', { muilt: 0, vendorId: val.id})
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  return (
    <div>
      <Form onSubmit={onSearch}  {...formItemLayout} className="ant-advanced-search-formTwo">
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={10}>
              <Col span={6}>
                <FormItem label="厂商ID" style={{ textAlign: "center" }}>
                  {getFieldDecorator('vendorId')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={5} className="search-buttons">
                <Button type="primary" style={{ marginTop: 3 }} htmlType="submit" icon="search">
                  查询
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
      <Table
        dataSource={list.list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: list.count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000'],
          showTotal: total => {
            let totalPages = Math.ceil(total / pageSize)
            return `共 ${total} 条记录，第 ${currentPage}/${totalPages} 页`
          }
        }}
        onChange={onChangeTable}
        scroll={{ x: 2000 }}
      >
        <Column title="项目" dataIndex="projectName" fixed="left" width={150} />
        <Column title="产品" dataIndex="productName" fixed="left" width={150} />
        <Column title="提交端口" dataIndex="supplierName" />
        <Column title="厂商ID" dataIndex="vendor" />
        <Column title="主体" dataIndex="relBranch" />
        <Column title="到期日" dataIndex="dqdate" />
        <Column title="创建时间" dataIndex="createTime" />
        <Column title="最后续费订单编号" dataIndex="lastNumber" />
        <Column title="最后续费人" dataIndex="lastUserName" />
        <Column title="最后续费时间" dataIndex="lastAffectedate" />
        <Column title="关联客服" dataIndex="relServicerName" />
        <Column title="关联销售" dataIndex="relSalerName" />
        <Column title="关联代理商开发" dataIndex="relAgentStaffName" />
        {/* <Column title="操作" dataIndex="" /> */}
        <Column title="操作" fixed="right" width={100} render={(text, record) => (
          <>
            {
              record.type === 1 ? null :
                <a rel="noopener noreferrer" onClick={() => {
                  if (search && search.status == 0) {
                    return message.error("该客户未实名认证，请点申请认证通过后再发起该操作！")
                  }
                  onOut(record)
                }}>转出</a>
            }
            {' '}
            {
              <a rel="noopener noreferrer" onClick={() => {
                if (search && search.status == 0) {
                  return message.error("该客户未实名认证，请点申请认证通过后再发起该操作！")
                }
                api.onCheckVendorRelServicer({
                  // client: record.clientId,
                  vendorId: record.id
                }).then(() => {
                  history.push('/orderDirect/renew', { relationId: record.clientId, vendorId: record.id })
                })
              }} style={{ marginLeft: 5 }}>续费</a>
            }
          </>
        )} />
      </Table>
      <Drawer
        title="厂商ID转出"
        width={880}
        onClose={() => { setOutVisible(false) }}
        visible={outVisible}
        destroyOnClose={true}
      >
        <Spin spinning={outloading}>
          <VendorTransfer
            setOutVisible={setOutVisible}
            setOutLoading={setOutLoading}
            oaIdVal={oaIdVal}
            vendorVal={vendorVal}
            historyVal={history}
          />
        </Spin>
      </Drawer>
    </div>
  )
}

export default Form.create()(VendorInfoList)