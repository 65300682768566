/**
 * 模块名称: 客户管理 -> 新增编辑联系人
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Form,
  Input,
  message,
} from 'antd'
import BtnGroup from '@/components/BtnGroup'

const FormItem = Form.Item
const ModifyInfo = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form
  const { seteditVisible, getList, setEditLoading, addType, editId, oaId } = props
  const [saveLoading, setSaveLoading] = useState(false)

  useEffect(() => {
    onEdit()
  }, [])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    }
  }

  // 编辑框
  const onEdit = () => {
    if (addType == 1) {
      setEditLoading(true)
      api.getClientContactDetail({ id: editId }).then(res => {
        setEditLoading(false)
        setFieldsValue({
          name: res.name,
          mobile: res.mobile,
          mail: res.mail,
          tel: res.tel,
          qq: res.qq,
          fax: res.fax
        })
      }).catch(() => {
        setEditLoading(false)
      })
    }
  }

  // 保存联系人
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        if (editId) {
          vals.id = editId
        }
        setSaveLoading(true)
        api.getClientContactHandle({
          clientId: oaId,
          name: vals.name,
          mobile: vals.mobile,
          mail: vals.mail,
          tel: vals.tel,
          qq: vals.qq,
          fax: vals.fax,
          id: vals.id
        })
          .then(() => {
            setSaveLoading(false)
            seteditVisible(false)
            if (editId) {
              message.success('编辑成功')
            } else {
              message.success('保存成功')
            }
            getList()
          })
          .catch(() => setSaveLoading(false))
      }
    })
  }


  return (
    <div>
      <Form onSubmit={formSubmit} {...formItemLayout}>
        <FormItem label="联系人姓名">
          {getFieldDecorator('name', {
            rules: [{ required: true, message: '请输入联系人姓名' }]
          })(
            <Input placeholder="请输入联系人姓名" />
          )}
        </FormItem>
        <FormItem label="手机号码">
          {getFieldDecorator('mobile', {
            rules: [
              { required: true, message: '请输入手机号码' }
              // ,
              // {
              //   pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '手机号格式不正确'
              // }
            ]
          })(
            <Input placeholder="请输入手机号码" />
          )}
        </FormItem>
        <FormItem label="邮箱">
          {getFieldDecorator('mail', {
            rules: [{ required: true, message: '请输入邮箱' },
              {
                pattern: /^\S+@\S+\.\S{2,}$/, message: '邮箱格式不正确'
              }
            ]
          })(
            <Input placeholder="请输入邮箱" />
          )}
        </FormItem>
        <FormItem label="座机">
          {getFieldDecorator('tel', {
            rules: [{ required: false, message: '请输入座机' }]
          })(
            <Input placeholder="请输入座机" />
          )}
        </FormItem>
        <FormItem label="QQ">
          {getFieldDecorator('qq')(
            <Input placeholder="请输入QQ" />
          )}
        </FormItem>
        <FormItem label="传真">
          {getFieldDecorator('fax')(
            <Input placeholder="请输入传真" />
          )}
        </FormItem>
        <BtnGroup onCancel={() => seteditVisible(false)} loading={saveLoading} />
      </Form>
    </div>
  );
}

export default Form.create()(ModifyInfo)

