/**
 * 模块名称: 客户管理 => 新增客户
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Form,
  Input,
  Select,
  Upload,
  message,
  Icon,
  Row,
  Col,
  Radio,
  Modal
} from 'antd'
import BtnGroup from '@/components/BtnGroup'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'

const FormItem = Form.Item
const Option = Select.Option
const { Dragger } = Upload

const AddCustomer = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form
  const { history } = props
  const [fileList, setFileList] = useState([])
  const draggerRef = useRef(null)
  const search = parseSearch(props.location.search)
  const [selectData, setSelectData] = useState({
    VerifyTypesArr: [], // 认证号码类型
    IndustryData: [], // 行业数据
    AreaDataArr: [], // 地区数据
    VerifyModeArr: [], // 认证模式
    isRequest: false
  })
  const [Modelvisible, setModelvisible] = useState(false)
  const [valModelvisible, setValModelvisible] = useState('')
  const [paramsVal, setParamsVal] = useState('')
  const [industryFlag, setIndustryFlag] = useState(true)
  const [twoIndustryArr, setTwoIndustryArr] = useState([]) //二级行业
  const [isJump, setIsJump] = useState(false) // 认证页面弹窗后是否跳转
  const [jumpOaId, setJumpOaId] = useState('') // 弹窗后跳转时oaId

  useEffect(() => {
    if (search.id) {
      onQuestionDetail() 
    } else if(search.clientId) {
      certificationDetail() 
    } else if(search.jumpId) {
      onJumpDetail(search.jumpId)
    }
    getSelectData()
  }, [])

  // 获取数据
  const getSelectData = () => {
    return Promise.all([
      api.getClientVerifyTypes({}),
      api.getSysIndustryList({ pid: 0 }),
      api.getClientGetAreaData({}),
      api.getClientVerifyMode({}),
    ]).then(list => {
      setSelectData({
        VerifyTypesArr: list[0],
        IndustryData: list[1],
        AreaDataArr: list[2],
        VerifyModeArr: list[3],
        isRequest: true
      })
      return list
    }).catch()
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 2 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 22 },
    }
  }

  // 驳回重提编辑返显
  const onQuestionDetail = () => {
    api.getClientVerifyDetail({
      id: search.id,
      type: '1'
    }, true).then(async res => {
      await api.getSysIndustryList({ pid: res.industry1 === 0 ? '' : res.industry1 }).then(data => {
        setTwoIndustryArr(data)
      })
      await setFieldsValue({
        name: res.name,
        // industry: res.industry,
        industry1: res.industry1 === 0 ? undefined : res.industry1,
        industry2	: res.industry2	  === 0 ? undefined : res.industry2	,
        website: res.website,
        verifyType: res.verifyType,
        verifyNumber: res.verifyNumber,
        area: res.area,
        address: res.address,
        contactName: res.contactName,
        contactMobile: res.contactMobile,
        contactMail: res.contactMail,
        contactTel: res.contactTel,
        contactQQ: res.contactQQ,
        contactFax: res.contactFax,
        isVerify: res.isVerify,
        remark: res.remark
      })
      if (res.affix.length) {
        res.affix.forEach((item, index) => {
          fileList.push({
            status: 'done',
            uid: index,
            name: item.name,
            url: item.url
          })
        })
        setFileList(res.affix.map((item, index) => ({...item, uid: index})))
      }
    })
  }

  // 点击申请认证
  const certificationDetail = () => {
    api.getClientGetDetail({oaId: search.clientId}, true).then(async res => {
      await api.getSysIndustryList({ pid: res.industry1 === 0 ? '' : res.industry1}).then(data => {
        setTwoIndustryArr(data)
      })
      await setFieldsValue({
        name: res.name,
        // industry: res.industry,
        industry1: res.industry1 === 0 ? undefined : res.industry1,
        industry2: res.industry2 === 0 ? undefined : res.industry2,
        website: res.website,
        verifyType: res.verifyType,
        verifyNumber: res.verifyNumber,
        area: res.area,
        address: res.address,
        isVerify: 0, // 默认为申请认证
      })
    })
  }

  //提交表单
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      let params = Object.assign({}, vals)
      let uploadList = []
      for (let i = 0, k = fileList.length; i < k; i++) {
        if (fileList[i].status !== 'removed') {
          uploadList.push({
            url: fileList[i].url,
            name: fileList[i].name
          })
        }
      }
      params.affix = uploadList
      if (search.id) params.id = search.id
      if (!search.id) params.id = ''
      if (search.clientId) params.clientId = search.clientId
      //console.log(match.path)
      if (!err) {
        setParamsVal('')
        api.getClientVerifyHandle(params).then(res => {
          setParamsVal(params)
          if (res.length == 0) {
            if (!search.id && !search.clientId ) {
              message.success('新增成功')
              history.push('/csr/customer')
            } else if (search.clientId && !search.edit) {
              message.success('申请认证成功')
              history.push('/csr/addAudit')
            } else if (search.edit == 1) {
              message.success('编辑成功')
              history.push('/csr/addAudit')
            } else {
              message.success('编辑成功')
              history.push('/csr/customer')
            }
          } else {
            //  确认弹窗展示
            if (res.isConfirm == true) {
              setModelvisible(true)
              setValModelvisible(res.msg)
              if (res.isJump) {
                setIsJump(res.isJump)
                setJumpOaId(res.oaId)
              } else {
                setIsJump(false)
                setJumpOaId('')
              }
            } else {
              message.success(res.msg)
              history.push('/csr/customer')
            }
          }
        })
      }
    })
  }

  // const onSecondary = () => {
  //   api.getClientVerifyHandle({ ...paramsVal, affirmFlag: 1 }).then(res => {
  //     history.push('/csr/customer')
  //   })
  // }

  //取消新增
  const onCancel = () => {
    history.goBack()
  }

  // 文件上传 
  const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.gif, .png, .jpg, .jpeg, .PDF, .zip',
		allowSizeType: 2
	})

  // 获取二级行业
  const onSelectIndustry = (val) => {
    setFieldsValue({
      industry2: undefined
    })
    api.getSysIndustryList({
      pid: val,
    }).then(data => {
      setTwoIndustryArr(data)
      setIndustryFlag(false)
    })
  }

  // 温馨提示
  const hintTitle = () => {
    return <div className='customerAdd-hintTitle'>
      <Icon type="warning" theme="twoTone" twoToneColor="#faad14" style={{ fontSize: '26px' }} />
      <div className='hintText'>温馨提示</div>
    </div>
  }

  const onHintOK = () => {
    if (search.clientId && isJump) {
      // history.replace('/csr/customer/addCustomer')
      history.replace(`/csr/customer/addCustomer?jumpId=${jumpOaId}`)
      window.location.reload()
      // onJumpDetail(jumpOaId)
      document.documentElement.scrollTop = 0
    }
    setModelvisible(false)
  }

  // 申请认证提交，弹出弹窗后，点击继续反显重复已认证的客户信息部分
  const onJumpDetail = (jumpOaId) => {
    api.getClientGetDetail({ oaId: jumpOaId }, true).then(async (res) => {
      await api.getSysIndustryList({ pid: res.industry1 === 0 ? '' : res.industry1 }).then(data => {
        setTwoIndustryArr(data)
      })
      const { name, industry1, industry2, website, verifyType, verifyNumber, area, address } = res
      await setFieldsValue({
        name,
        industry1: industry1 === 0 ? undefined : industry1,
        industry2: industry2 === 0 ? undefined : industry2,
        website,
        verifyType,
        verifyNumber,
        area,
        address,
        isVerify: undefined
      })
    })
  }
  
  return (
    <div className="customerSty">
      {
        !search.clientId ?
          <Form onSubmit={formSubmit} {...formItemLayout}>
            {/* 客户信息 */}
            <div className="CustomerLable">
              <h4 style={{ marginTop: 8 }}>客户信息</h4>
              <div className="customerLine"></div>
              <div>
                <Row gutter={24}>
                  <Col span={6}>
                    <FormItem label="客户名称" labelCol={{ span: 9 }}>
                      {getFieldDecorator('name', {
                        rules: [{ required: true, message: '请输入客户名称' },
                        {
                          pattern: /^[\u4e00-\u9fa5|a-zA-Z|（）|《》｜、]{0,}$/,
                          message: '只支持中文、大小写英文字母、《》、中文括号及中文顿号',
                        }
                        ]
                      })(<Input style={{ width: 200 }} placeholder="请输入客户名称" />)}
                    </FormItem>
                  </Col>
                  {/* <Col span={6}>
                    <div className="csrManageNo">
                      <FormItem label="所在行业" labelCol={{ span: 9 }}>
                        {getFieldDecorator('industry', {
                          rules: [{ required: true, message: '请选择所在行业' }]
                        })(
                          <Select placeholder="请选择"
                            style={{ width: 200 }}
                            dropdownMatchSelectWidth={false}
                          >
                            {selectData.IndustryData.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                          </Select>
                        )}
                      </FormItem>
                    </div>
                  </Col> */}
                  <Col span={6}>
                    <FormItem label="一级行业" labelCol={{ span: 9 }}>
                      {getFieldDecorator('industry1', {
                        rules: [{ required: true, message: '请选择一级行业' }]
                      })(
                        <Select placeholder="请选择"
                          style={{ width: 200 }}
                          dropdownMatchSelectWidth={false}
                          onSelect={onSelectIndustry}
                          showSearch={true}
										      optionFilterProp="children"
                        >
                          {selectData.IndustryData.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label="二级行业" labelCol={{ span: 9 }}>
                      {getFieldDecorator('industry2', {
                        rules: [{ required: twoIndustryArr.length === 0 ? false : true, message: '请选择二级行业' }]
                      })(
                        <Select placeholder="请选择"
                          style={{ width: 200 }}
                          dropdownMatchSelectWidth={false}
                          showSearch={true}
										      optionFilterProp="children"
                          disabled={industryFlag} 
                        >
                          {twoIndustryArr&&twoIndustryArr.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label="网址" labelCol={{ span: 6 }}>
                      {getFieldDecorator('website', {
                        rules: [{ required: true, message: '请输入网址' }]
                      })(<Input style={{ width: 200 }} placeholder="请输入网址" />)}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={6}>
                    <FormItem label="认证号码类型" labelCol={{ span: 12 }}>
                      {getFieldDecorator('verifyType', {
                        rules: [{ required: true, message: '请选择认证号码类型' }]
                      })(
                        <Select placeholder="请选择"
                          style={{ width: 200 }}
                          dropdownMatchSelectWidth={false}
                        >
                          {selectData.VerifyTypesArr.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label="认证号码" labelCol={{ span: 9 }}>
                      {getFieldDecorator('verifyNumber', {
                        normalize: verifyNumber => {
                          if (verifyNumber !== undefined) {
                            return verifyNumber.toUpperCase()
                          } else {
                            return verifyNumber
                          }
                        },
                        rules: [{ required: true, message: '请输入认证号码' }]
                      })(<Input style={{ width: 200 }} placeholder="请输入认证号码" />)}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <div className="csrManageNo">
                      <FormItem label="地区" labelCol={{ span: 6 }}>
                        {getFieldDecorator('area', {
                          rules: [{ required: true, message: '请选择地区' }]
                        })(
                          <Select placeholder="请选择"
                            style={{ width: 200 }}
                            dropdownMatchSelectWidth={false}
                          >
                            {selectData.AreaDataArr.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                          </Select>
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  <Col span={6}>
                    <FormItem label="地址" labelCol={{ span: 6 }}>
                      {getFieldDecorator('address', {
                        rules: [{ required: true, message: '请输入地址' }]
                      })(<Input style={{ width: 200 }} placeholder="请输入地址" />)}
                    </FormItem>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="line"></div>

            {/* 联系人信息 */}
            <div className="CustomerLable">
              <h4 style={{ marginTop: 12 }}>联系人信息</h4>
              <div className="customerLine"></div>
              <div>
                <Row gutter={24}>
                  <Col span={6}>
                    <FormItem label="联系人姓名" labelCol={{ span: 10 }}>
                      {getFieldDecorator('contactName', {
                        rules: [{ required: true, message: '请输入联系人姓名' }]
                      })(<Input style={{ width: 200 }} placeholder="请输入联系人姓名" />)}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label="手机号码" labelCol={{ span: 9 }}>
                      {getFieldDecorator('contactMobile', {
                        rules: [{ required: true, message: '请输入手机号码' },
                        {
                          pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '手机号格式不正确'
                        }],
                      })(<Input style={{ width: 200 }} placeholder="请输入手机号码" />)}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label="邮箱" labelCol={{ span: 6 }}>
                      {getFieldDecorator('contactMail', {
                        rules: [{ required: true, message: '请输入邮箱' },
                        {
                          pattern: /^\S+@\S+\.\S{2,}$/, message: '邮箱格式不正确'
                        }]
                      })(<Input style={{ width: 200 }} placeholder="请输入邮箱" />)}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label="&nbsp; 座机" labelCol={{ span: 6 }}>
                      {getFieldDecorator('contactTel', {
                        rules: [{ required: false, message: '请输入座机' }]
                      })(<Input style={{ width: 200 }} placeholder="请输入座机" />)}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={6}>
                    <FormItem label="&nbsp; QQ" labelCol={{ span: 6 }}>
                      {getFieldDecorator('contactQQ', {
                        // rules: [{ required: true, message: '请输入QQ' }]
                      })(<Input style={{ width: 200 }} placeholder="请输入QQ" />)}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label="&nbsp; 传真" labelCol={{ span: 6 }}>
                      {getFieldDecorator('contactFax')(<Input style={{ width: 200 }} placeholder="请输入传真" />)}
                    </FormItem>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="line"></div>
            {/* 其他信息 */}
            <div>
              <h4 style={{ marginTop: 12 }}>其他信息</h4>
              <div className="customerLine"></div>
              <div>
                <Form.Item label="是否认证">
                  {getFieldDecorator('isVerify', {
                    rules: [{ required: true, message: '请选择是否认证' }]
                  })(
                    // {VerifyModeArr.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    <Radio.Group>
                      {
                        selectData.VerifyModeArr.map(item => <Radio key={item.id} value={item.id}>{item.name}</Radio>)
                      }
                    </Radio.Group>
                  )}
                </Form.Item>
                <FormItem label="附件">
                  <div className="dragger-box" ref={draggerRef}>
                    <Dragger
                      {...uploadFiles}
                    >
                      <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                      <p className="ant-upload-hint">
                        附件支持格式：GIF、PNG、JPG、PDF、zip  支持添加多个附件，单个附件大小不超过5M，文件数量不超过5个。
    </p>
                    </Dragger>
                  </div>
                </FormItem>
                <FormItem label="备注">
                  {getFieldDecorator('remark')(
                    <Input.TextArea placeholder="请输入备注" maxLength={100} />
                  )}
                </FormItem>
              </div>
            </div>
            <div style={{ paddingRight: 50 }}>
              <BtnGroup onCancel={onCancel} confirmName='提交' right />
            </div>
          </Form>
          :
          <Form onSubmit={formSubmit} {...formItemLayout}>
            {/*点击申请认证时 客户信息 */}
            <div className="CustomerLable">
              <h4 style={{ marginTop: 8 }}>客户信息</h4>
              <div className="customerLine"></div>
              <div>
                <Row gutter={24}>
                  <Col span={6}>
                    <FormItem label="客户名称" labelCol={{ span: 9 }}>
                      {getFieldDecorator('name', {
                        rules: [{ required: true, message: '请输入客户名称' },
                        {
                          pattern: /^[\u4e00-\u9fa5|a-zA-Z|（）|0-9｜《》｜、]{0,}$/,
                          message: '括号只支持中文括号、书名号、中文顿号,不支持空格等特殊符号', 
                        }
                        ]
                      })(<Input style={{ width: 200 }} placeholder="请输入客户名称" disabled={true} />)}
                    </FormItem>
                  </Col>
                  {/* <Col span={6}>
                    <div className="csrManageNo">
                      <FormItem label="所在行业" labelCol={{ span: 9 }}>
                        {getFieldDecorator('industry', {
                          rules: [{ required: true, message: '请选择所在行业' }]
                        })(
                          <Select placeholder="请选择"
                            style={{ width: 200 }}
                            dropdownMatchSelectWidth={false}
                            disabled={true}
                          >
                            {selectData.IndustryData.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                          </Select>
                        )}
                      </FormItem>
                    </div>
                  </Col> */}
                  <Col span={6}>
                    <FormItem label="一级行业" labelCol={{ span: 9 }}>
                      {getFieldDecorator('industry1', {
                        rules: [{ required: true, message: '请选择一级行业' }]
                      })(
                        <Select placeholder="请选择"
                          style={{ width: 200 }}
                          dropdownMatchSelectWidth={false}
                          onSelect={onSelectIndustry}
                          disabled={true}
                          showSearch={true}
                          optionFilterProp="children"
                        >
                          {selectData.IndustryData.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label="二级行业" labelCol={{ span: 9 }}>
                      {getFieldDecorator('industry2', {
                        rules: [{ required: twoIndustryArr.length === 0 ? false : true, message: '请选择二级行业' }]
                      })(
                        <Select placeholder="请选择"
                          style={{ width: 200 }}
                          dropdownMatchSelectWidth={false}
                          disabled={true}
                          showSearch={true}
                          optionFilterProp="children"
                        >
                          {twoIndustryArr && twoIndustryArr.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label="网址" labelCol={{ span: 6 }}>
                      {getFieldDecorator('website', {
                        rules: [{ required: true, message: '请输入网址' }]
                      })(<Input style={{ width: 200 }} placeholder="请输入网址" disabled={true} />)}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={6}>
                    <FormItem label="认证号码类型" labelCol={{ span: 12 }}>
                      {getFieldDecorator('verifyType', {
                        rules: [{ required: true, message: '请选择认证号码类型' }]
                      })(
                        <Select placeholder="请选择"
                          style={{ width: 200 }}
                          dropdownMatchSelectWidth={false}
                          disabled={true}
                        >
                          {selectData.VerifyTypesArr.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label="认证号码" labelCol={{ span: 9 }}>
                      {getFieldDecorator('verifyNumber', {
                        rules: [{ required: true, message: '请输入认证号码' }]
                      })(<Input style={{ width: 200 }} placeholder="请输入认证号码" disabled={true} />)}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <div className="csrManageNo">
                      <FormItem label="地区" labelCol={{ span: 6 }}>
                        {getFieldDecorator('area', {
                          rules: [{ required: true, message: '请选择地区' }]
                        })(
                          <Select placeholder="请选择"
                            style={{ width: 200 }}
                            dropdownMatchSelectWidth={false}
                            disabled={true}
                          >
                            {selectData.AreaDataArr.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                          </Select>
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  <Col span={6}>
                    <FormItem label="地址" labelCol={{ span: 6 }}>
                      {getFieldDecorator('address', {
                        rules: [{ required: true, message: '请输入地址' }]
                      })(<Input style={{ width: 200 }} placeholder="请输入地址" disabled={true} />)}
                    </FormItem>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="line"></div>
            {/* 其他信息 */}
            <div>
              <h4 style={{ marginTop: 12 }}>其他信息</h4>
              <div className="customerLine"></div>
              <div>
                <Form.Item label="是否认证">
                  {getFieldDecorator('isVerify', {
                    rules: [{ required: true, message: '请选择是否认证' }]
                  })(
                    <Radio.Group disabled={true}>
                      {
                        selectData.VerifyModeArr.map(item => <Radio key={item.id} value={item.id}>{item.name}</Radio>)
                      }
                    </Radio.Group>
                  )}
                </Form.Item>
                <FormItem label="附件">
                  <div className="dragger-box" ref={draggerRef}>
                    <Dragger
                      {...uploadFiles}
                    >
                      <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                      <p className="ant-upload-hint">
                        附件支持格式：GIF、PNG、JPG、PDF、zip  支持添加多个附件，单个附件大小不超过5M，文件数量不超过5个。
    </p>
                    </Dragger>
                  </div>
                </FormItem>
                <FormItem label="备注">
                  {getFieldDecorator('remark')(
                    <Input.TextArea placeholder="请输入备注" maxLength={100} />
                  )}
                </FormItem>
              </div>
            </div>
            <div style={{ paddingRight: 50 }}>
              <BtnGroup onCancel={onCancel} confirmName='提交' right />
            </div>
          </Form>

      }
      <Modal
        title={hintTitle()}
        visible={Modelvisible}
        onOk={onHintOK}
        maskClosable={false}
        closable={false}
        okText="确认"
        onCancel={() => setModelvisible(false)}
        width='50%'
      >
        <div style={{ whiteSpace: 'pre-wrap' }}>
          {valModelvisible}
        </div>
      </Modal>
    </div>
  )
}

export default Form.create()(AddCustomer)