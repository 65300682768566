/**
 * 模块名称: 我的厂商ID列表
 * @author yue
 */

import React, { useEffect, useState, useRef } from 'react'
import api from '@/api'
import { Alert, Button, Col, Form, Icon, Input, Row, Table, message } from 'antd'
// import { Link } from 'react-router-dom'
import { debounce } from '@/utils/index'
import Export from '@/components/Export'
import Auth from 'components/AuthMiddleware'

const { Column } = Table
const FormItem = Form.Item
let pageSize = 10
let currentPage = 1
let defaultPage = 1

let searchParams = {
	page: currentPage,
	limit: pageSize,
}
let allFilter = {}

const FirmList = (props) => {
	const { history, match } = props
	const { getFieldDecorator, validateFields, resetFields } = props.form
	const [loading, setLoading] = useState(false)
	const [list, setList] = useState([])
	const [count, setCount] = useState(0)
	const [expand, setExpand] = useState(false)
	const productsRef = useRef()
	const [showExport, setShowExport] = useState(false)
    const [authList, setAuthList] = useState([])

	useEffect(() => {
		searchParams = {
			page: currentPage,
			limit: pageSize,
		}
		getList()
		api.getPageAuth().then(list => setAuthList(list))
	}, [])

	//获取列表数据
	const getList = (params = '') => {
		setLoading(true)
		params = params ? params : searchParams
		api.getMyFirmList(params)
			.then(data => {
				setLoading(false)
				setList(data.list)
				setCount(data.count)
			})
			.catch(() => setLoading(false))
	}

	//查询
	const onSearch = (e) => {
		e.preventDefault()
		history.replace(match.path)
		currentPage = 1
		getListForParams(defaultPage)
	}

	//重置
	const onReset = () => {
		currentPage = 1
		history.replace(match.path)
		resetFields()
		allFilter = {}
		getListForParams(defaultPage)
	}

	/**
	 * 分页跳转
	 * @param pagination
	 * @param filters
	 * @param sorter
	 */
	const onChangeTable = (pagination, filters, sorter) => {
		searchParams.page = currentPage = pagination.current
		searchParams.limit = pageSize = pagination.pageSize
		history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}`)
		getListForParams(pagination.current, pagination.pageSize)
	}

	/**
	 * 根据筛选项获取列表
	 */
	const getListForParams = (page = '', limit = '') => {
		page = page ? page : currentPage;
		limit = limit ? limit : pageSize;

		searchParams.page = currentPage = page
		searchParams.limit = pageSize = limit

		validateFields((error, value) => {
			if (!error) {
				allFilter = { ...value }
				getList({ ...searchParams, ...value });
			}
		})
	}

	//筛选项收起、展开
	const onToggle = () => {
		setExpand(!expand)
	}

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		}
	}

	// 导出
	const onExportFile = () => {
		setShowExport(true)
	}

	return (
		<>
			<Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
				<div className="search-item-wrap">
					<div className="search-item">
						<Row gutter={24}>
							<Col span={6}>
								<FormItem label="OA客户名称:" labelCol={{ span: 8 }}>
									{getFieldDecorator('client')(<Input placeholder="请输入OA客户名称" />)}
								</FormItem>
							</Col>
							<Col span={6}>
								<FormItem label="厂商ID" labelCol={{ span: 8 }}>
									{getFieldDecorator('vendor')(<Input placeholder="请输入厂商ID" />)}
								</FormItem>
							</Col>
							<Col span={6}>
								<FormItem label="项目" labelCol={{ span: 8 }}>
									{getFieldDecorator('project')(<Input placeholder="请输入项目" />)}
								</FormItem>
							</Col>
							<Col span={6}>
								<FormItem label="产品" labelCol={{ span: 8 }}>
									{getFieldDecorator('product')(<Input placeholder="请输入产品" />)}
								</FormItem>
							</Col>
						</Row>
						<Row gutter={24} className={expand ? '' : 'hide'}>
							<Col span={6}>
								<FormItem label="关联销售" labelCol={{ span: 6 }}>
									{getFieldDecorator('saleName')(<Input placeholder="请输入关联销售" />)}
								</FormItem>
							</Col>
							<Col span={6}>
								<FormItem label="关联客服" labelCol={{ span: 6 }}>
									{getFieldDecorator('serviceName')(<Input placeholder="请输入关联客服" />)}
								</FormItem>
							</Col>
							<Col span={10}>
								<FormItem label="关联代理商开发" labelCol={{ span: 6 }}>
									{getFieldDecorator('agentStaffName')(<Input placeholder="请输入关联代理商开发" />)}
								</FormItem>
							</Col>
						</Row>
					</div>
					<span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle}
						style={{ cursor: 'pointer', fontSize: '12px', color: '#1890ff' }}>
						<span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
					</span>
				</div>

				<div className="recruit-search-btns">
					<Auth auths={authList} code="export">
						<Button type="primary" icon="upload" onClick={onExportFile} style={{ marginRight: 8 }}>导出</Button>
					</Auth>
					<Button onClick={onReset}>重置</Button>
					<Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
				</div>
			</Form>
			<div className="line"></div>
			<div className="add-wrap">
			</div>
			<Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
			<Table
				size="small"
				dataSource={list}
				rowKey="id"
				className="table-page"
				loading={loading}
				pagination={{
					pageSize,
					total: count,
					current: currentPage,
					showQuickJumper: true,
					showSizeChanger: true,
					pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
				}}
				scroll={{ x: 1800 }}
				onChange={onChangeTable}
			>
				<Column title="OA客户名称" dataIndex="client" fixed="left" width={160} />
				<Column title="OAID" dataIndex="clientId" />
				<Column title="所属代理商" dataIndex="agentName" />
				<Column title="代理商QID" dataIndex="agentId" />
				<Column title="厂商账号" dataIndex="account" />
				<Column title="厂商ID" dataIndex="vendor" />
				<Column title="项目" dataIndex="projectName" />
				<Column title="产品" dataIndex="productName" width={110} />
				<Column title="关联销售" dataIndex="relSalerName" />
				<Column title="关联客服" dataIndex="relServicerName" />
				<Column title="关联代理商开发" dataIndex="relAgentStaffName" />
				<Column title="操作" key="set" fixed="right" width={150} render={(text, record) => {
					return record.operateFlag && <>
						<span className="invoice-form-btn">

							<a onClick={debounce(() => {
								api.getCustomerGetInfo({
									type: record.type,
									id: record.type == 0 ? record.clientId : record.agentId,
									relation: 0
								}).then(res => {
									if (res.status == 0) {
										return message.error('该客户未实名认证，请申请认证通过后再发起该操作！')
									}
									api.onCheckVendorRelServicer({
										// client: record.clientId,
										vendorId: record.id
									  }).then(() => {
										if (record.type == 1) {
											history.push('/orderAgent/renew', {
												relationId: record.clientId,
												vendorId: record.id,
												qId: record.agentId
											})
										}
										else {
											history.push('/orderDirect/renew', {
												relationId: record.clientId,
												vendorId: record.id
											})
										}
									})
								})
							}, 2000)}>续费</a>

						</span>
						<span className="invoice-form-btn">
							<a onClick={debounce(() => {
								api.getCustomerGetInfo({
									type: record.type,
									id: record.type == 0 ? record.clientId : record.agentId,
									relation: 0
								}).then(res => {
									if (res.status == 0) {
										return message.error('该客户未实名认证，请申请认证通过后再发起该操作！')
									}
									history.push(`/fund/refundManage/add?relationId=${record.type ? record.agentId : record.clientId}&relationType=${record.type}`)
								})
							}, 2000)}>退款</a>
							{/* <Link to={`/fund/refundManage/add?relationId=${record.type ? record.agentId : record.clientId}&relationType=${record.type}`}>退款</Link> */}
						</span>
						<span className="invoice-form-btn">
							<a onClick={debounce(() => {
								api.getCustomerGetInfo({
									type: record.type,
									id: record.type == 0 ? record.clientId : record.agentId,
									relation: 0
								}).then(res => {
									if (res.status == 0) {
										return message.error('该客户未实名认证，请申请认证通过后再发起该操作！')
									}
									history.push(`/fund/moneyTransmit/add?clientId=${record.type ? record.agentId : record.clientId}&clientType=${record.type}`)
								})
							}, 2000)}>转款</a>
							{/* <Link to={`/fund/moneyTransmit/add?clientId=${record.type ? record.agentId : record.clientId}&clientType=${record.type}`}>转款</Link> */}
						</span>
					</>
				}} />
			</Table>
			<Export
				show={showExport}
				onCancel={() => {
					setShowExport(false)
					productsRef.current.changeVal()
				}}
				tplUrl="getFortuneVendortExportTpl"
				fieldsUrl="getFortuneVendortTplItems"
				saveUrl="saveFortuneVendorExportTpls"
				exportUrl="exportFortuneVendorExport"
				method="export"
				parame={{ ExportType: 'vendor.export', ...allFilter }}
				cRef={productsRef}
			/>
		</>
	)
}

export default Form.create()(FirmList)
