/**
 * 模块名称: 客户管理
 * @author zhuyan@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import CsrManagement from './CsrManagement'
import CsrDetail from './CsrDetail'
import AddCustomer from './module/AddCustomer' // 新增信息
import CustomerChange from './module/CustomerChange' // 信息变更
import HeadSetup from './HeadSetup'
import VendorTransferAdd from './module/VendorTransferAdd'
import VendorBatchAdd from './module/VendorBatchAdd'
import NewVendor from './NewVendor'

const CsrManagementIndex = ({ match, history }) => {

  return (
    <div>
      <Switch>
        {/* <Route path={match.path + '/csrManagement'} component={CsrManagement} /> */}
        <Route path={match.path + '/detail'} component={CsrDetail} />
        <Route path={match.path + '/addCustomer'} component={AddCustomer} />
        {/* 客户详情进入新建客户 */}
        <Route path={match.path + '/applyTo'} component={AddCustomer} /> 
        {/* 客户新增审批编辑进入新建客户 */}
        <Route path={match.path + '/editCustomer'} component={AddCustomer} /> 
        <Route path={match.path + '/customerChange'} component={CustomerChange} />
        <Route path={match.path + '/headSetup'} component={HeadSetup} />
        <Route path={match.path + '/vendorTransferAdd'} component={VendorTransferAdd} />
        <Route path={match.path + '/vendorBatchAdd'} component={VendorBatchAdd} />
        <Route path={match.path + '/newVendor'} component={NewVendor} />
        <Route component={CsrManagement} />
      </Switch>
    </div>
  )
}

export default CsrManagementIndex