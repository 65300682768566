/**
 * 模块名称: 分配销售
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Button,
  message,
} from 'antd'

const { Column } = Table

let filter = {
  name: '',
}

const Distribution = (props) => {
  const { setSalesVisible, setSalesLoading, companyId, vendorId, getList, setSelectedIds } = props
  const [salesList, setSalesList] = useState([])
  const [stfIdVal, setStfIdVal] = useState('')
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  useEffect(() => {
    filter = {
      name: '',
    }
    // getDirectVendorStaffList()
  }, [])


  const getDirectVendorStaffList = () => {
    setSalesLoading(true)
    api.getDirectVendorStaffList({ name: filter.name, type: 2, companyId: companyId })
      .then(data => {
        setSalesLoading(false)
        setSalesList(data.list)
      })
      .catch(() => setSalesLoading(false))
  }

  const departInp = useRef(null)
  const onSearch = () => {
    filter.name = departInp.current.value
    if (departInp.current.value == '') {
      message.info("请输入员工姓名")
      return
    }
    setStfIdVal('')
    setSelectedRowKeys([])
    getDirectVendorStaffList()
  }

  //获取单选值
  // const onSelectChange = (selectedRowKeys) => {
  //   // console.log("selectedRowKeys[0]", selectedRowKeys.join())
  //   setStfIdVal(selectedRowKeys.join())
  // }

  const rowSelection = {
    type:'radio',
    onChange: (selectedRowKeys, selectedRows) => {
      setStfIdVal(selectedRowKeys.join())
      setSelectedRowKeys(selectedRowKeys)
    },
    selectedRowKeys: selectedRowKeys
  }

  const onSalesSubmit = () => {
    if (stfIdVal == '') {
      message.info("请选择员工")
      return
    }
    api.setDirectVendorSaler({ staffId: stfIdVal, vendorId: vendorId.join(',') })
      .then(data => {
        message.success("提交成功")
        setSalesVisible(false)
        setSelectedIds('')
        getList()
      }).catch()
  }

  const onKeyup = (e) => {
    if (e.keyCode === 13) {
      onSearch()
    }
  }

  return (
    <>
      <div className="vendorstry">
        <div className="vendorwrap">
          <div className="vendor-item">
            <div style={{ width: 100 }}>员工姓名: </div>
            <input className="ant-input" ref={departInp} type="text" placeholder="请输入员工姓名" onKeyUp={onKeyup} />
          </div>
          <Button onClick={onSearch} type="primary">查询</Button>
        </div>
        <Table
          dataSource={salesList}
          rowKey="id"
          className="table-page"
          pagination={false}
          rowSelection={rowSelection}
          // rowSelection={{ onChange: onSelectChange, type: 'radio' }}
        >
          <Column title="员工姓名" dataIndex="staffName" />
          <Column title="职位" dataIndex="jobPosition" />
          <Column title="所属部门" dataIndex="departmentName" />
          <Column title="所属公司" dataIndex="companyName" />
        </Table>

        <div style={{ height: 60 }}></div>
        <div className="setVendorstryBtn">
        </div>
        <div className="setVendorstryBtn">
          <Button onClick={() => { setSalesVisible(false) }} style={{ margin: 20 }}>取消</Button>
          <Button type="primary" onClick={onSalesSubmit}>提交</Button>
        </div>
      </div>
    </>
  )
}

export default Distribution