/**
 * 模块名称: 厂商ID修改
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
	Drawer, Form, Button, Col, Row, Input, Select, message, Radio
} from 'antd'

const { Option } = Select
const FormItem = Form.Item

let receiveTimer = null

const CsrManagementRepair = (props) => {
	const { setVisibleRepair, visibleRepair, getList } = props
	const { getFieldDecorator, validateFields, setFieldsValue } = props.form

	const [vendor, setVendor] = useState({})
	const [agent, setAgent] = useState({})//代理商
	const [client, setClient] = useState({})//直销
	const [relBranch, setRelBranch] = useState([])//主体
	const [service, setService] = useState([])//客户
	const [market, setMarket] = useState([])//销售
	const [channel, setChannel] = useState([])//渠道

	const [subjectVal, setSubjectVal] = useState('')//主体
	const [serviceVal, setServiceVal] = useState('')//客户
	const [marketVal, setMarketVal] = useState('')//销售
	const [channelVal, setChannelVal] = useState('')//渠道


	useEffect(() => {
	}, [])

	const onClose = () => {
		setVisibleRepair(false)
	}

	//提交form表单
	const handleSubmit = (e) => {
		e.preventDefault()
		validateFields((err, values) => {
			if (!err) {
				// console.log('Received values of form: ', values)
				// console.log(vendor)
				api.setEditInfoByVendor({
					vendor: values.vendor,
					type: values.type,
					client: client.name ? client.name : vendor.client,
					clientId: values.clientId,
					agentId: values.agentId,
					agentName: agent.name ? agent.name : vendor.agentName,
					relSaler: values.relSaler ? values.relSaler : vendor.relSaler,
					relSalerName: marketVal !== '' ? marketVal : vendor.relSalerName,
					relServicer: values.relServicer ? values.relServicer : vendor.relServicer,
					relServicerName: serviceVal !== '' ? serviceVal : vendor.relServicerName,
					relAgentStaff: values.relAgentStaff ? values.relAgentStaff : vendor.relAgentStaff,
					relAgentStaffName: channelVal !== '' ? channelVal : vendor.relAgentStaffName,
					relBranch: subjectVal !== '' ? subjectVal : vendor.relBranch,
					relBranchId: values.relBranchId,
					vendorClient: values.vendorClient,
					account: values.account,
					jkstatus: values.jkstatus,
					iskpi: values.iskpi,
					money: values.money
				}).then(res => {
					setVisibleRepair(false)
					getList()
					message.success('修改成功')
					setVendor({})
					setAgent({})
					setClient({})
				}).catch(err => console.log(err))
			}
		})
	}

	//输入厂商id
	const onManufacturer = (e) => {
		// console.log(e.target.value)
		let val = e.target.value
		if (val !== '') {
			if (receiveTimer) {
				clearTimeout(receiveTimer)
			}
			receiveTimer = setTimeout(() => {
				receiveTimer = null
				clearTimeout(receiveTimer)
				api.getInfoByVendor({ vendor: val }).then(res => {
					// console.log(res)
					if (!Array.isArray(res)) {
						commonality()
						setFieldsValue({
							agentId: res.agentId,
							clientId: res.clientId,
							relBranchId: res.relBranchId,
							type: res.type,
							vendorClient: res.vendorClient,
							account: res.account,
							jkstatus: Number(res.jkstatus),
							iskpi: Number(res.iskpi),
							money: Number(res.money)
						})
					}
					setVendor(res)
				})
			}, 1000)
		}
	}

	//公共接口
	const commonality = () => {
		api.getSelectCompanyList({ limit: global.paramsLimit }).then(data => setRelBranch(data.list))//主体
		api.getDepartmentStaff({ type: 1 }).then(res => setService(res))//客服
		api.getDepartmentStaff({ type: 0 }).then(res => setMarket(res))//销售
		api.getDepartmentStaff({ type: 2 }).then(res => setChannel(res))//渠道
	}

	//关联代理商
	const onAgent = (e) => {
		// console.log(e.target.value)
		let val = e.target.value
		if (val !== '') {
			if (receiveTimer) {
				clearTimeout(receiveTimer)
			}
			receiveTimer = setTimeout(() => {
				receiveTimer = null
				clearTimeout(receiveTimer)
				api.getCustomerGetInfo({ id: val, type: 1, relation: 1 }, true).then(res => {
					// console.log(res)
					setAgent(res)
				})
			}, 1000)
		}
	}

	//关联客户
	const onClient = (e) => {
		// console.log(e.target.value)
		let val = e.target.value
		if (val !== '') {
			if (receiveTimer) {
				clearTimeout(receiveTimer)
			}
			receiveTimer = setTimeout(() => {
				receiveTimer = null
				clearTimeout(receiveTimer)
				api.getCustomerGetInfo({ id: val, type: 0, relation: 1 }, true).then(res => {
					// console.log(res)
					setClient(res)
				})
			}, 1000)
		}
	}

	//主体
	const onSubject = () => {
		api.getSelectCompanyList({ limit: global.paramsLimit }).then(data => setRelBranch(data.list))
	}

	//主体选中
	const onSelectSubject = (value, option) => {
		// console.log(value, option.props.children)
		setSubjectVal(option.props.children)
	}

	//客服
	const onService = () => {
		api.getDepartmentStaff({ type: 1 }).then(res => {
			// console.log(res)
			setService(res)
		})
	}

	//选中客服
	const onSelectService = (value, option) => {
		// console.log(value, option.props.children)
		setServiceVal(option.props.children)
	}

	//销售
	const onMarket = () => {
		api.getDepartmentStaff({ type: 0 }).then(res => {
			// console.log(res)
			setMarket(res)
		})
	}

	//选中销售
	const onSelectMarket = (value, option) => {
		// console.log(value, option.props.children)
		setMarketVal(option.props.children)
	}

	//渠道
	const onChannel = () => {
		api.getDepartmentStaff({ type: 2 }).then(res => {
			// console.log(res)
			setChannel(res)
		})
	}

	//选中渠道
	const onSelectChannel = (value, option) => {
		// console.log(value, option.props.children)
		setChannelVal(option.props.children)
	}

	return (
		<>
			<Drawer
				title="厂商ID修改"
				width={720}
				onClose={onClose}
				visible={visibleRepair}
				bodyStyle={{ paddingBottom: 80 }}
				destroyOnClose={true}
			>
				<Form layout="vertical" onSubmit={handleSubmit}>
					<Row gutter={16}>
						<Col span={24}>
							<FormItem label="厂商ID（多个请用英文逗号分隔）">
								{getFieldDecorator('vendor', {
									rules: [{ required: true, message: '请输入' }],
								})(<Input placeholder="请输入" onChange={onManufacturer} />)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<FormItem label="关联代理商">
								<span>{agent.name ? agent.name : vendor.agentName}</span>
								{getFieldDecorator('agentId', {
									// rules: [{ required: true, message: '请选择' }],
								})(<Input placeholder="请输入" onChange={onAgent} />)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<FormItem label="关联客户">
								<span>{client.name ? client.name : vendor.client}</span>
								{getFieldDecorator('clientId', {
									// rules: [{ required: true, message: '请选择' }],
								})(<Input placeholder="请输入" onChange={onClient} />)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<FormItem label="厂商客户名称">
								{getFieldDecorator('vendorClient', {
									// rules: [{ required: true, message: '请选择' }],
								})(<Input placeholder="请输入"/>)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<FormItem label="厂商客户账号">
								{getFieldDecorator('account', {
									// rules: [{ required: true, message: '请选择' }],
								})(<Input placeholder="请输入"/>)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<FormItem label="结款状态">
								{getFieldDecorator('jkstatus', {
									// rules: [{ required: true, message: '请选择' }],
								})(<Radio.Group>
									<Radio value={1}>已结</Radio>
									<Radio value={0}>未结</Radio>
								</Radio.Group>)}
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem label="是否算KPI">
								{getFieldDecorator('iskpi', {
									// rules: [{ required: true, message: '请选择' }],
								})(<Radio.Group>
									<Radio value={1}>是</Radio>
									<Radio value={0}>否</Radio>
								</Radio.Group>)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<FormItem label="充值类型">
								{getFieldDecorator('money', {
									// rules: [{ required: true, message: '请选择' }],
								})(<Radio.Group>
									<Radio value={1}>现金</Radio>
									<Radio value={2}>虚拟金</Radio>
									<Radio value={3}>固定账期</Radio>
								</Radio.Group>)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<FormItem label="主体">
								{getFieldDecorator('relBranchId', {
									// rules: [{ required: true, message: '请选择' }],
								})(
									<Select
										placeholder="请选择"
										onDropdownVisibleChange={bool => bool && onSubject()}
										onSelect={onSelectSubject}
										showSearch={true}
										optionFilterProp="children"
									>
										{
											relBranch.length > 0 && relBranch.map((item => {
												return <Option value={item.id} key={item.id}>{item.name}</Option>
											}))
										}
									</Select>
								)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<FormItem label="类型">
								{getFieldDecorator('type', {
									// rules: [{ required: true, message: '请选择' }],
									initialValue: ''
								})(
									<Select placeholder="请选择">
										<Option value={0}>直销</Option>
										<Option value={1}>代理商</Option>
									</Select>,
								)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={8}>
							<FormItem label="原客服">
								<div>{vendor.relServicerName == '' ? '--' : vendor.relServicerName}</div>
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem label="原销售">
								<div>{vendor.relSalerName == '' ? '--' : vendor.relSalerName}</div>
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem label="原所属渠道开发">
								<div>{vendor.relAgentStaffName == '' ? '--' : vendor.relAgentStaffName}</div>
							</FormItem>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={8}>
							<FormItem label="新客服">
								{getFieldDecorator('relServicer', {
									// rules: [{ required: true, message: '请选择' }],
								})(
									<Select
										placeholder="请选择"
										onDropdownVisibleChange={bool => bool && onService()}
										showSearch={true}
										optionFilterProp="children"
										onSelect={onSelectService}
									>
										{
											service.length > 0 && service.map(item => {
												return <Option value={item.id} key={item.id}>{item.staffName}</Option>
											})
										}
									</Select>
								)}
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem label="新销售">
								{getFieldDecorator('relSaler', {
									// rules: [{ required: true, message: '请选择' }],
								})(
									<Select
										placeholder="请选择"
										onDropdownVisibleChange={bool => bool && onMarket()}
										showSearch={true}
										optionFilterProp="children"
										onSelect={onSelectMarket}
									>
										{
											market.length > 0 && market.map(item => {
												return <Option value={item.id} key={item.id}>{item.staffName}</Option>
											})
										}
									</Select>
								)}
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem label="新所属渠道开发">
								{getFieldDecorator('relAgentStaff', {
									// rules: [
									// 	{
									// 		required: true,
									// 		message: '请选择',
									// 	},
									// ],
								})(
									<Select
										placeholder="请选择"
										onDropdownVisibleChange={bool => bool && onChannel()}
										showSearch={true}
										optionFilterProp="children"
										onSelect={onSelectChannel}
									>
										{
											channel.length > 0 && channel.map(item => {
												return <Option value={item.id} key={item.id}>{item.staffName}</Option>
											})
										}
									</Select>
								)}
							</FormItem>
						</Col>
					</Row>
				</Form>
				<div
					style={{
						position: 'absolute',
						right: 0,
						bottom: 0,
						width: '100%',
						borderTop: '1px solid #e9e9e9',
						padding: '10px 16px',
						background: '#fff',
						textAlign: 'right',
					}}
				>
					<Button onClick={onClose} style={{ marginRight: 8 }}>取消</Button>
					<Button onClick={handleSubmit} type="primary">确定</Button>
				</div>
			</Drawer>
		</>
	)
}

export default Form.create()(CsrManagementRepair)