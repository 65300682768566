/**
 * 模块名称: 新批量转移厂商信息列表
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Button,
  Input,
  message,
  Row,
  Col,
} from 'antd'

const { Column } = Table
const FormItem = Form.Item
let pageSize = 10
let currentPage = 1

let filter = {
  vendor: '',
  client: '',
  clientId: ''
}

const VendorsList = (props) => {
  const { match, location, muilt, client, clientId, setSelectedRowKeys, selectedRowKeys } = props
  const { getFieldDecorator, validateFields, resetFields } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)

  useEffect(() => {
    currentPage = 1
    pageSize = 10
    filter = {
      vendor: '',
      client: '',
      clientId: ''
    }
    getList()
  }, [])

  const getList = () => {
    setLoading(true)
    let param
    if (muilt === 1) {
      param = { ...filter, muilt, client, clientId, limit: pageSize, page: currentPage }
    } else if (muilt === 2) {
      param = { ...filter, muilt, limit: pageSize, page: currentPage }
    }
    api.getTransferVendors(param)
      .then(data => {
        setLoading(false)
        setList(data.list)
        setCount(data.count)
      })
      .catch(() => setLoading(false))
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        currentPage = 1
        filter = {
          ...filter,
          ...vals,
        }
        getList()
      }
    })
  }

  // 重置
  const onReset = () => {
    resetFields()
    filter = {
      vendor: '',
      client: '',
      clientId: ''
    }
    currentPage = 1
    getList()
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    getList()
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys)
    },
    selectedRowKeys: selectedRowKeys
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 7 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 17 },
    }
  }
  return (
    <>
      <Form onSubmit={onSearch} {...formItemLayout} style={{ marginTop: '16px' }}>
        <Row gutter={24}>
          <Col span={6}>
            <FormItem label="厂商ID">
              {getFieldDecorator('vendor')(<Input placeholder="请输入" />)}
            </FormItem>
          </Col>
          {muilt === 2 &&
            <>
              <Col span={7}>
                <FormItem label="OA客户名称">
                  {getFieldDecorator('client')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={5}>
                <FormItem label="OAID">
                  {getFieldDecorator('clientId')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </>
          }
          <Col span={4} style={{ marginTop: '4px' }}>
            <Button onClick={onReset}>重置</Button>
            <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
          </Col>
        </Row>
      </Form>
      {/* <Alert className="corp-count" message={
         <>
           <span>已选择 <span style={{color: '#168FFF'}}>{selectedCount}</span> 项</span>
           <span style={{ marginLeft: 20 }}>总共{count}条数据</span>
         </>
       } type="info" showIcon /> */}
      <Table
        size="middle"
        dataSource={list}
        rowKey="id"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1600 }}
        onChange={onChangeTable}
        rowSelection={rowSelection}
      >
        <Column title="项目" dataIndex="projectName" fixed='left' />
        <Column title="产品" dataIndex="productName" />
        <Column title="厂商ID" dataIndex="vendor" />
        <Column title="厂商属性" dataIndex="typeName" />
        <Column title="OA客户名称" dataIndex="client" />
        <Column title="OAID" dataIndex="clientId" />
        <Column title="关联主体" dataIndex="relBranch" />
        <Column title="关联销售" dataIndex="relSalerName" />
        <Column title="关联客服" dataIndex="relServicerName" />
        <Column title="关联代理商开发" dataIndex="relAgentStaffName" />
        <Column title="创建时间" dataIndex="createTime" />
      </Table>
    </>
  )
}

export default Form.create()(VendorsList)
